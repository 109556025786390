<template>
	<div class="slot-card__move-behavior" 
	ref="calendar-slot"
	v-bind:style="{...styles}">
		<slot></slot>
	</div>
</template>

<script>
import CalendarWorkTime from '../../../scripts/calendarWorkTime';
import { dateIsPast, isEqualDate, isToday } from '../../../scripts/date';
import { makeDraggable, makeUndraggable } from '../../../scripts/draggable';
import { scrollByCondition } from '../../../scripts/scroll';
import Slot from '../../../scripts/slot';


function eventToCoordinates(event) { 
	const isPointerEvent = 'PointerEvent' in window && event instanceof PointerEvent
	return isPointerEvent ? 
		{x: event.clientX, y: event.clientY}:
		{x: event.pageX, y: event.pageY} 
}

export default {
	name: 'SlotCardMoveBehavior',
	
	emits: [
		'on-move-start',
		'on-move',
		'on-move-end'
	],

	props: {
		eventSlot: {
			type: Slot,
			required: true,
		},
		workTime: {
			type: CalendarWorkTime,
			required: true,
		},
		cellHeight: {
			type: Number,
			default: 54,
		},
		height: {
			type: Number,
			default: 54,
		},
		slotCard: HTMLElement,
		slotContainer: HTMLElement,
		isReplaceable: {
			type: Boolean,
			default: true,
		},
		isMoveToOrigin: {
			type: Boolean,
			default: false,
		},
		currentHour: {
			type: Number, 
			required: true,
		},
		currentMinute: {
			type: Number, 
			required: true,
		},
		dragDelay: {
			type: Number,
			default: 300,
		},
		scrollStep: {
			type: Number,
			required: true,
		},
		timeIsWrong: {
			type: Boolean,
			default: false,
		},
		dateIsWrong: {
			type: Boolean,
			default: false,
		},
	},
	
	computed: {

		top(){
			return Math.floor(this.posY)
		},
		left(){
			return Math.floor(this.posX)
		},
		styles(){
			return {
				left: this.left + 'px',
				top: this.top + 'px',
			}
		}
	},

	data() {
		return {
			posY: 0,
			originY: 0,
			posX: 0,
			isTouched: false,
			dragging: null,
			dragLimits: null,
			containerSizes: null,
			scrollInterval: null,
			scrollSpeed: 0,
			width: 0,
			futureColumn: null,
			columnsX: null,

			isDraggable: false,
		};
	},

	watch: {
		slotCard(value){
			this.isDraggable = false
			this.makeDraggable()
		},
		isMoveToOrigin(value){
			if (!value)
				return
			this.posY = this.originY
			this.posX = 0
		}
	},

	created() {
		this.posY = this.roundPositionByTime()
		this.originY = this.posY
	},

	mounted(){
		this.makeDraggable()
		if (this.slotCard)
			this.width = this.slotCard.offsetWidth
	},
	
	updated(){
		if (this.slotCard)
			this.width = this.slotCard.offsetWidth
	},

	beforeDestroy() {
		this.makeUndraggable()	
	},

	methods: {

		// tableCurrentTimeLimiter(posY) {
		// 	if (!isToday(this.eventSlot.period.start.date))
		// 		return false
		// 	let currentTimeLine = this.slotContainer.querySelector('.red__line')
		// 	if (!currentTimeLine)
		// 		return false
		// 	let linePositionTop = parseFloat(currentTimeLine.style.top)
		// 	if (linePositionTop < 0 || linePositionTop == NaN)
		// 		return false
		// 	return linePositionTop > posY
		// },

		pointerDownHandler(event){
			this.isTouched = true
			if ( !this.workTime.isAllowPastTime && dateIsPast(this.eventSlot.period.start.date))
				return
			if ( !this.isReplaceable)
				return
			setTimeout(() => {
				if (!this.isTouched || 
					this.eventSlot.settings.isQuestionSlot ||
					!this.replacePreparation(event))
					return

				this.originY = this.posY

				const supportsPointerEvents = 'PointerEvent' in window;

				this.$emit('on-move-start')

				if (supportsPointerEvents)
					this.$el.addEventListener('pointermove', this.pointerMoveHandler);
				else
					this.$el.addEventListener('mousemove', this.pointerMoveHandler);
				
				if (window.navigator.vibrate)
					window.navigator.vibrate(65)
			}, this.dragDelay)
		},

		replacePreparation(event){

			if (!this.slotContainer)
				return false

			// Init table sizes
			let containerSizes = this.slotContainer.getBoundingClientRect()
			let elementSizes = this.$el.getBoundingClientRect()

			let sizes = {}
			sizes["scroll-area"] = this.slotContainer.scrollHeight
			sizes["height"] = Math.round(containerSizes.height - elementSizes.height)
			sizes["width"] = Math.round(containerSizes.width - elementSizes.width)

			const hoursContainer = this.slotContainer.querySelector('.calendar_hours_ruler')
			const hoursContainerSizes = hoursContainer.getBoundingClientRect()
			sizes["left"] = hoursContainerSizes.width + containerSizes.left

			this.containerSizes = sizes

			const limits = {}
			limits["top"] = 0
			limits["bottom"] = this.containerSizes["scroll-area"] - this.height - 10
			limits["left"] = elementSizes.left * -1 + this.containerSizes["left"]
			limits["right"] = this.containerSizes["width"] + limits["left"] - 30 - 16
			this.dragLimits = limits

			this.columnsX = Array.from(this.slotContainer.querySelectorAll(".calendar-column"))

			const currentColumn = this.columnsX.find((columnElem) => {
				return columnElem.id == `column-${this.eventSlot.period.start.date.toDateString()}`
			})
			const currentColumnPosX = currentColumn.getBoundingClientRect().left

			this.columnsX = this.columnsX.map((columnElem) => {
				return columnElem.getBoundingClientRect().left - currentColumnPosX
			})
			
			// Init start position of a drag and drop

			let posX = event.pageX - event.offsetX + this.dragLimits["left"] - 40
			let posY = event.pageY - containerSizes.top - event.offsetY - 32
			
			// Init start point 
			let {x, y} = eventToCoordinates(event);
			this.dragging = {dx: posX - x, dy: posY - y}
			
			return true
		},

		pointerMoveHandler(event){

			if (!this.dragging) 
				return 

			let {x, y} = eventToCoordinates(event);

			let posX = x + this.dragging.dx
			let posY = y + this.dragging.dy + this.slotContainer.scrollTop

			posX = Math.round(Math.max(this.dragLimits["left"], Math.min(posX, this.dragLimits["right"])))
			posY = Math.round(Math.max(this.dragLimits["top"], Math.min( posY, this.dragLimits["bottom"])))

			let isMovingDown = this.posY < posY
      		this.posY = posY 
			this.posX = posX

			this.scrollContainer(this.posY, isMovingDown)

			this.futureColumn = this.calcFutureColumn(posX)

			if (!this.scrollInterval){
				this.$emit('on-move', {
					posX, posY, 
					futureColumnDate: this.futureColumn,
				})
			}
		},

		scrollContainer(posY, isMovingDown){
			let relativeY = Math.round(posY - this.slotContainer.scrollTop)
			
			let scrollBuffer = 0
			let contHeight = this.containerSizes["height"]
			let contScroll = this.containerSizes["scroll-area"]
	
			let tableTopLimiter = (target) => target.scrollTop > 0 && !isMovingDown
			let tableBotLimiter = (target) => Math.floor(contScroll - contHeight - target.scrollTop - this.height) > 0 && isMovingDown

			/* 
				Interrupt the table scrolling if 
				1. The slot is in the non-border area
				2. The slot is in the top border area and the table is scrolled to the top
				3. The slot is in the bottom border area and the table is scrolled to the bottom
				4. The slot date is today and slot position top is less than the red (current time) line
			*/  
			
			let isNonBorderArea = relativeY - this.cellHeight > scrollBuffer  &&
								relativeY - contHeight + this.cellHeight < scrollBuffer

			let isTopBorderArea = relativeY - this.cellHeight <= scrollBuffer 
								&& !tableTopLimiter(this.slotContainer) && !isMovingDown

			let isBotBorderArea = relativeY - contHeight + this.cellHeight > scrollBuffer 
								&& !tableBotLimiter(this.slotContainer) && isMovingDown

			if (isNonBorderArea || isTopBorderArea || isBotBorderArea) { 
				if (this.scrollInterval){
					clearInterval(this.scrollInterval)
					this.scrollInterval = null
				}
				this.scrollSpeed = null
				return
			}

			/*
				Update the scroll speed if the slot is in the border area
			*/

			if (relativeY - this.cellHeight <= scrollBuffer)
				this.scrollSpeed = Math.floor(Math.abs(relativeY - this.cellHeight) / this.scrollStep) * -1
			else if (relativeY - contHeight + this.cellHeight >= scrollBuffer)
				this.scrollSpeed = Math.floor(Math.abs(relativeY - contHeight + this.cellHeight) / this.scrollStep) + 1

			if (this.scrollInterval)
				return
			
			let scrollCondition 

			// Scroll to the top
			if (relativeY - this.cellHeight <= scrollBuffer && this.slotContainer.scrollTop != 0) {
				this.scrollSpeed *= -1
				scrollCondition = (target) => tableTopLimiter(target)
			// Scroll to the bottom
			} else if (relativeY - contHeight + this.cellHeight >= scrollBuffer) {
				scrollCondition = (target) => tableBotLimiter(target)
			} else {
				return 
			}

			// Update the position y and slot time
			let onScroll = (difference) => {
				let futureY = this.posY + this.scrollSpeed
				this.posY = Math.max(0, Math.min(futureY, this.containerSizes["scroll-area"] - this.height - 10))
				this.$emit('on-move', {
					posX: this.posX, posY: this.posY,
					futureColumnDate: this.futureColumn,
				})
			}

			// Start scrolling using interval
			this.scrollInterval = scrollByCondition(this.slotContainer, () => this.scrollSpeed, scrollCondition, {
				onScroll,
				delay: 15,
			})			
		},		

		pointerUpHandler(){

			this.isTouched = false
			this.dragging = null
			this.dragLimits = null

			this.scrollSpeed = null
			if (this.scrollInterval){
				clearInterval(this.scrollInterval)
				this.scrollInterval = null
			}

			const supportsPointerEvents = 'PointerEvent' in window;
			
			if (this.dragging && supportsPointerEvents) {
				this.$el.addEventListener('pointermove', this.pointerMoveHandler);
			} else if (this.dragging) {
				this.$el.addEventListener('mousemove', this.pointerMoveHandler);
			}
			
			// If future datetime is wrong -> return to the origin position
			if (this.timeIsWrong || this.dateIsWrong) {
				this.posY = this.originY
				this.posX = 0
			} else {
				this.posY = this.roundPositionByTime()
			}

			if (isEqualDate(this.futureColumn, this.eventSlot.period.start.date))
				this.posX = 0
			
			this.$emit('on-move-end', {
				futureColumnDate: this.futureColumn,
			})
			// If future column is defined, then change the column
			if (this.futureColumn) {
				this.colorizeFutureColumn(null)
				this.futureColumn = null
			}
		},
		

		topPositionByTime(hour, minute){
			let topPosition = hour * this.cellHeight + minute * this.cellHeight / 60
			return Math.floor(topPosition)
		},
		
		makeDraggable(){

			if (!this.isReplaceable)
				return 

			makeDraggable(this.slotCard, {
				start: this.pointerDownHandler,
				end: this.pointerUpHandler
			})
		},
		
		makeUndraggable(){
			
			if (!this.isReplaceable)
				return 
			
			if (this.isDraggable) {
				makeUndraggable(this.slotCard, {
					start: this.pointerDownHandler,
					end: this.pointerUpHandler,
				})
				this.isDraggable = false
			}
		},

		calcFutureColumn(posX){
			if (!this.columnsX || this.columnsX.length == 1)
				return this.eventSlot.period.start.date

			const columnCountLeft = this.columnsX
				.map((value) => value < 0 ? 1 : 0)
				.reduce((acc, value) => acc + value, 0) 
			const closestColumn = this.columnsX.map((columnX, index) => {
				return {
					index: index - columnCountLeft,
					diff: Math.abs(columnX - posX)
				}
			}).sort((a, b) => a.diff - b.diff)[0]

			const closestColumnDate = new Date(this.eventSlot.period.start.date)
			closestColumnDate.setDate(closestColumnDate.getDate() + closestColumn.index)

			this.colorizeFutureColumn(closestColumnDate)
			return closestColumnDate
		},

		colorizeFutureColumn(futureColumn){
			const columnTitles = Array.from(this.slotContainer.querySelectorAll('.calendar-date-title__item'))
			if (!futureColumn) {
				columnTitles.forEach((titleElem) => {
					titleElem.classList.remove('selected')
				})
				return
			}

			columnTitles.forEach((titleElem) => {
				titleElem.classList.remove('selected')
			})

			const columnDate = columnTitles.find((titleElem) => {
				const id = futureColumn.toLocaleDateString()
				return titleElem.id == `calendar-date-title__item-${id}`
			})
			if (!columnDate)
				return
			columnDate.classList.add('selected')
		},

		roundPositionByTime(){
			let {hour, minute} = this.workTime.startTime()
			let workStartAlign = this.topPositionByTime(hour, minute)
			let relativeTop = this.topPositionByTime(this.currentHour, this.currentMinute) 
			return relativeTop - workStartAlign
		}
	},
};
</script>

<style scoped>
.slot-card__move-behavior{
	position: absolute;
	height: fit-content;
	left: 0;
	width: 100%;
}
</style>