
function getTextWidth(text, fontSize) {
	const measureElement = document.createElement('div');
	measureElement.className = 'measure-text';
	measureElement.style.width = 'fit-content';
	measureElement.style.fontSize = `${fontSize}px`;
	measureElement.textContent = text;
	
	document.body.appendChild(measureElement);
	
	const width = measureElement.offsetWidth;
	
	document.body.removeChild(measureElement);
	
	return width;
}


function getNamePart( elemHTML, text,){
	if (!elemHTML)
		return 
	let iter = 0
	const nameChars = text.split('')
	let nameFirstPart = ""
	while (iter < text.length) {
		nameFirstPart += nameChars.shift()
		elemHTML.textContent = nameFirstPart

		if (getTextWidth(nameFirstPart, 10) >= elemHTML.offsetWidth) {
			nameFirstPart = nameFirstPart.slice(0, -1)
			break
		}
		iter += 1;
	}
	return nameFirstPart
}

export {
	getNamePart, getTextWidth
}