class ScrollOptions {
	constructor(onScroll, delay) {
		this.onScroll = onScroll
		this.delay = delay
	}
}

/**
 * Make scroll of the target function while scrollCondition function return true 
 * @param {HTMLElement} target Target HTML element to scroll 
 * @param {Function} getSpeed Getter for scrolling speed
 * @param {ScrollOptions} opts Contain additional options for the scroll:
 * - delay - delay between executions of the scroll in milliseconds (default 10)
 * - onScroll - function that execute after each one small scroll (default null) 
 * @returns Interval instance
 */
function scrollByCondition(target, getSpeed, scrollCondition, opts={
	onScroll: null, 
	delay: 10
}) {
	let speed
	let difference = 0 
	let updatedScroll = target.scrollTop

	let scrollInterval = setInterval(() => {
		if (!scrollCondition(target)) {
			return clearInterval(scrollInterval)
		}

		speed = getSpeed()

		updatedScroll += speed
		difference += speed
		target.scrollTo(0, updatedScroll)

		if (opts.onScroll)
			opts.onScroll(target)
	}, opts.delay)
	return scrollInterval
}

export {
	scrollByCondition
}