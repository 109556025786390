<template>
	<div class="calendar-week-bar">
		<div v-for="day in weekDaysItems"  class="calendar-week-item"
		:key="`day-${day.index}`" 
		v-bind:class="{
			'is-holiday' : isHoliday(day.index + 1) || isSpecialDay(day.index + 1) 
		}">
			{{ day.title }}
		</div>
	</div>
</template>

<script>

const daysNames = [
	'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat', 'Sun'
]

export default {
	name: 'WeekBar',

	props: {
		collapseStep: {
			type: Number,
			required: true
		}
	},

	computed: {
		weekDaysItems(){

			let weekDays = [0, 1, 2, 3, 4, 5, 6 ]
			return weekDays.map((day) => {
				return {
					index: day,
					title: daysNames[day],
				}
			})
		},
		
	},

	methods: {
		isHoliday(date){
			const calendar = this.$store.getters.currentCalendar()
			if (!calendar || !calendar.workDays)
				return false
			if (date == 7)
				date = 0
            return calendar.workDays.isHoliday(date)
        },
        isSpecialDay(date){
			const calendar = this.$store.getters.currentCalendar()
			if (!calendar || !calendar.workDays)
				return false
			if (date == 7)
				date = 0
            return calendar.workDays.isSpecialDate(date)
        },
	},
};
</script>

<style scoped>
.calendar-week-bar{
	display: flex;
	flex-direction: row;
	gap: 4px;
	padding-left: 32px;
	padding-right: 18px;
	width: 100%;
}

.calendar-week-item {
	flex: 1;
	text-align: center;
	font-size: 12px;
}


.list-enter-active,
.list-leave-active {
  	transition: all 0.5s ease;
}
.list-enter-from{
	flex: 1;
}

.list-leave-to {
	flex: 0;
	opacity: 0;
}

.calendar-week-item.is-holiday{
	color: #da7f78;
}

</style>