<template>
	<SlotCardMoveBehavior
	:eventSlot="eventSlot"
	:workTime="workTime"
	:cellHeight="cellHeight"
	:isMoveToOrigin="isMoveToOrigin"
	:slotCard="slotCard"
	:slotContainer="slotContainer"
	:isReplaceable="isReplaceable && !isUpdatingTime"
	:height="height"
	:currentHour="currentHour"
	:currentMinute="currentMinute"
	:scrollStep="scrollStep"
	:timeIsWrong="timeIsWrong"
	:dateIsWrong="dateIsWrong"
	@on-move-start="onMoveStart"
	@on-move="onMove"
	@on-move-end="onMoveEnd">

		<slot name="tools-buttons"></slot>

		<slot name="tools"></slot>

		<div class="calendar-slot"
		ref="slot-card"
		:id="`calendar-slot-${eventSlot.index}`"
		v-bind:style="styles"
		v-bind:class="{
			'is-top-neighbor': topNeighbor,
			'is-bot-neighbor': botNeighbor,
			'is-dragging' : isDragging || isUpdatingTime,
			'is-past' : isPast,
		}">

			<div class="calendar-slot-content">
				<div class="slot-header" ref="slot-header">
					<div ref="time" class="time">{{ `${currentHour}:${pair(currentMinute)} ` }}</div>
					<div ref="part-one" class="name-part-one"></div>
					<div ref="part-two" class="name-part-two"></div>
				</div>

				<div class="slot-footer" v-if="footerString">
					<b-icon :icon="footerIcon" size="is-small"></b-icon>
					<div class="slot-footer__title">
						{{ footerString }}
					</div>
				</div>
			</div>

		</div>
		<div class="calendar-slot-separator" v-if="botNeighbor"
		v-bind:style="{background : separatorColor()}">
		</div>
	</SlotCardMoveBehavior>
</template>

<script>
import CalendarWorkTime from '../../../scripts/calendarWorkTime';
import { dateIsPast, pair } from '../../../scripts/date';
import Slot from '../../../scripts/slot';
import SlotCollection from '../../../scripts/slotCollection';
import { getTimeByPosY } from '../../../scripts/slotTime';
import SlotCardMoveBehavior from './SlotCardMoveBehavior.vue';
import { darkenColor, hexToRgb, isDarkColor, lightenColor, rgbToHex } from '../../../scripts/color';
import { isLightColor } from '../../../scripts/color';
import { delLastPressedSlot, getLastPressedSlot } from '../../../scripts/lastPressedSlot';
import { getNamePart, getTextWidth } from '../../../scripts/slotCardTitle';

const CONFIG = {
	"drag-delay" : 300,
	"movement-step" : 10,
}

const SCROLL_STEPS = 3

export default {
	name: 'SlotCard',
	components: {
		SlotCardMoveBehavior,
	},
	props: {
		eventSlot: {
			type: Slot,
			required: true,
		},
		slotCollection: SlotCollection,
		workTime: {
			type: CalendarWorkTime,
			required: true,
		},
		cellHeight: {
			type: Number,
			default: 54,
		},
		slotContainer: {
			type: HTMLElement,
		},
		slotToolButtons: {
			type: HTMLElement,
		},	
		isReplaceable: {
			type: Boolean,
			default: true,
		},
		columnCount: {
			type: Number,
			default: 1,
		},
		viewType: {
			type: String,
			validation(value){
				return ['full', 'middle', 'short'].includes(value)
			}
		}
	},
	computed: {
		height(){
			return Math.floor(this.cellHeight * this.eventSlot.period.duration / 60)
		},
		styles(){
			const background = this.eventSlot.settings.cellBackgroundColor ?? 'lavender' 
			const textColor = this.eventSlot.settings.cellTextColor ?? 'black'
			let paddingRight = null

			if (this.viewType != 'short' && this.$slots['tools-buttons'] && this.slotToolButtons) {
				paddingRight = this.slotToolButtons.offsetWidth + 7 + 'px'
			}

			return {
				background: background,
				height: `${this.height}px`,
				color: textColor,
				boxShadow: this.shadowIsActive ? 
					`0px 2px 16px 0px ${this.separatorColor()}`:
					`0px 0px 0px 0px ${this.separatorColor()}`
			}
		},
		scrollStep(){
			return this.cellHeight / SCROLL_STEPS
		},
		isPast(){
			return dateIsPast(this.eventSlot.period.end.date)
		},
		topNeighbor(){
			this.top
			let hour = this.currentHour
			let minute = this.currentMinute

			let neighbor = this.slotCollection.neighborByEnd(this.eventSlot.index, {hour, minute})
			return neighbor
		},
		botNeighbor(){
			this.top
			let hour = this.currentHour
			let minute = this.currentMinute

			minute += this.eventSlot.period.duration
			while (minute >= 60){
				hour += 1
				minute -= 60
			}

			let neighbor = this.slotCollection.neighborByStart(this.eventSlot.index, {hour, minute})
			return neighbor
		},
		footerString(){
			const regular = this.eventSlot.settings.regular
			if (!regular)
				return null
			const chargingRule = regular.chargingRule
			if (chargingRule == 2 && regular.attendingUserId) {
				return regular.attendingUserId
					.map(id => this.slotCollection.relatedUsers[id])
					.filter(userName => !!userName)
					.join(', ')
			} else if (chargingRule == 3 && this.eventSlot.settings.hostId) {
				const hostId = this.eventSlot.settings.hostId
				return this.slotCollection.relatedUsers[hostId]
			}
			return null
		},
		footerIcon(){
			if (!this.eventSlot.settings.regular)
				return null
			const chargingRule = this.eventSlot.settings.regular.chargingRule
			if (chargingRule == 2) {
				return 'school'
			} else if (chargingRule == 3) {
				return 'account-voice'
			}
			return null
		},
	},

	watch: {
		topNeighbor(newValue, oldValue){
			let neighbor = newValue != undefined ?
				newValue :
				oldValue
			
			if (!neighbor)
				return

			let neighborHTML = document.querySelector(`#calendar-slot-${neighbor.index}`)
			if (!neighborHTML)
				return
			if (oldValue == undefined) {
				neighborHTML.classList.add('is-bot-neighbor')
			} else if (newValue == undefined) {
				neighborHTML.classList.remove('is-bot-neighbor')
			}
		},
		botNeighbor(newValue, oldValue){
			let neighbor = newValue != undefined ?
				newValue :
				oldValue
			
			if (!neighbor)
				return

			let neighborHTML = document.querySelector(`#calendar-slot-${neighbor.index}`)
			if (!neighborHTML)
				return
			if (oldValue == undefined) {
				neighborHTML.classList.add('is-top-neighbor')
			} else if (newValue == undefined) {
				neighborHTML.classList.remove('is-top-neighbor')
			}
		},
		columnCount(){
			if (this.eventSlot.title) {
				this.setupTitleParts(this.eventSlot.title)
			}
		},
	},

	data(){
		return {
			isDragging: false,
			isUpdatingTime: false,
			initialHour: 0,
			currentHour: 0,
			initialMinute: 0,
			currentMinute: 0,

			timeIsWrong: false,
			dateIsWrong: false,

			originScrollY: 0,
			shadowIsActive: false,
			slotCard: null,

			isMoveToOrigin: false,
		}
	},

	created(){
		this.initialHour = this.eventSlot.period.start.date.getHours()
		this.initialMinute = this.eventSlot.period.start.date.getMinutes()
		this.currentHour = this.initialHour
		this.currentMinute = this.initialMinute
	},

	mounted(){
		this.handleLastPressedSlot()
		this.slotCard = this.$refs['slot-card']

		let title = this.eventSlot.title
		if (title) {
			this.setupTitleParts(title)
		}
	},

	methods: {
		pair: pair,

		getNamePart: getNamePart,

		setupTitleParts(title){
			if (this.$refs["time"]) {
				let timeWidth = getTextWidth(this.$refs["time"].textContent, 10)
				this.$refs['time'].style.width = `${timeWidth}px`
				// timeWidth += 5
				this.$refs['slot-header'].style.gridTemplateColumns = `${timeWidth}px calc(100% - 5px - ${timeWidth}px)`
			}
			
			const namePartOne = this.getNamePart(this.$refs['part-one'], this.eventSlot.title)
			if (namePartOne)
				this.$refs['part-one'].textContent = namePartOne
			if (namePartOne == title) {
				this.$refs['part-two'].textContent = ""
				return
			}
			title = title.replace(namePartOne, '')
			this.$refs['part-two'].textContent = title
			if (this.columnCount == 7) {
				this.$refs['part-two'].style.maxWidth = '100%'
			} else if (this.columnCount == 1) {
				this.$refs['part-two'].style.maxWidth = 'calc(100% - 65px)'
			} else {
				this.$refs['part-two'].style.maxWidth = 'calc(100% - 20px)'
			}
		},	

		onMoveStart() {
			this.isDragging = true
			this.initialHour = this.currentHour
			this.initialMinute = this.currentMinute
			this.originScrollY = this.slotContainer.scrollTop
		},
		
		onMove({posX, posY, futureColumnDate}){
			const currentDate = this.eventSlot.period.start.date
			this.setTimeForPosition(posY, futureColumnDate ?? currentDate)
		},
		
		onMoveEnd({futureColumnDate}){
			let timeIsChanged = this.initialHour != this.currentHour 
							|| this.initialMinute != this.currentMinute
			this.isDragging = false
			if (this.timeIsWrong) {
				this.moveBackToOrigin()
				return
			}
			if (timeIsChanged && !this.eventSlot.settings.isQuestionSlot || futureColumnDate)
				this.updateTime(futureColumnDate)
		},

		moveBackToOrigin(){
			this.currentHour = this.initialHour
			this.currentMinute = this.initialMinute

			this.isMoveToOrigin = true
			setTimeout(() => this.isMoveToOrigin = false, 0)

			this.slotContainer.scrollTo({
				top: this.originScrollY,
				behavior: 'smooth'
			})
			this.activateSlotShadow()
		},

		separatorColor(){
			let background
			if (!this.eventSlot.settings || !this.eventSlot.settings.cellBackgroundColor)
				background = "#e6e6fa"
			else
				background = this.eventSlot.settings.cellBackgroundColor

			if (background == "lavender")
				background = "#e6e6fa"

			let rgb = hexToRgb(background)
			let color = {
				red: rgb[0],
				green: rgb[1],
				blue: rgb[2],
			}

			let isDark = isDarkColor(color)
			let isLight = isLightColor(color)
			if (isLight)
				return rgbToHex(darkenColor(color, 0.8))
			if (isDark)
				return rgbToHex(lightenColor(color, 0.2))
			return ("black")	
		},

		checkValidTime(futureColumnDate, hour, minute){
			let closestCreateTime = new Date()
			let current = new Date(futureColumnDate)
			current.setHours(hour, minute, 0, 0)
			this.timeIsWrong = closestCreateTime > current
		},

		setTimeForPosition(posY, futureColumnDate){
			const time = getTimeByPosY(posY, this.cellHeight, this.workTime, CONFIG["movement-step"])
			if (!this.workTime.isAllowPastTime) {
				this.checkValidTime(futureColumnDate, time.hour, time.minute)
			}

			this.currentHour = time.hour
			this.currentMinute = time.minute
		},

		updateTime(futureColumn){
			this.isUpdatingTime = true
			const newSlotTime = futureColumn ? 
				new Date(futureColumn) : new Date(this.eventSlot.period.start.date)
			newSlotTime.setHours(this.currentHour, this.currentMinute)
			let browserTZ = this.$store.getters.browserTimezone
            let calendarTZ = this.$store.getters.calendarTimezone
			this.slotCollection.updateSlotTime(this.eventSlot, newSlotTime, browserTZ, calendarTZ)
			.then(data => {
				if (data instanceof Error) {
					this.currentHour = this.initialHour
					this.currentMinute = this.initialMinute
					throw data
				}
				this.initialHour = this.currentHour
				this.initialMinute = this.currentMinute
			})
			.catch((err) => {
				console.error(err)
				this.moveBackToOrigin()
			})
			.finally(() => this.isUpdatingTime = false)
		},

		handleLastPressedSlot(){
			const lastPressedSlot = getLastPressedSlot()
			if (lastPressedSlot === undefined || lastPressedSlot !== this.eventSlot.index)
				return
			delLastPressedSlot()
			this.activateSlotShadow()
		},

		activateSlotShadow(){
			this.shadowIsActive = true
			setTimeout(() => this.shadowIsActive = false, 1500)
		},

	}
};
</script>

<style scoped>

.icon{
	display: flex;
	justify-content: flex-start;
}

.calendar-slot{
	position: relative;
	
	border-radius: 3px;
	padding: 3px;

	display: flex;
	justify-content: space-between;

	transition: box-shadow .6s;
}

.is-top-neighbor{
	border-top-left-radius: 0;
	border-top-right-radius: 0;
}

.is-bot-neighbor{
	border-bottom-left-radius: 0;
	border-bottom-right-radius: 0;
}

.calendar-slot-content{
	display: flex;
	flex-direction: column;
	justify-content: space-between;
	font-size: 10px;	
	width: 100%;
}

.calendar-slot-separator{
	position: absolute;
	z-index: 3;
	bottom: -1px;
	left: 20px;
	right: 20px;
	height: 2px;
	border-radius: 2px;
	background: black;
}

.tools-header{
	display: flex;
	flex-direction: row;
	gap: 10px;
}

.is-dragging {
	z-index: 1000;
}

.is-past{
	filter: grayscale(0.5);
}

.slot-header{
	font-size: 10px;
	max-height: 29px;
    display: grid;
	column-gap: 5px;
    grid-template-columns: 6ch calc(100% - 5px - 6ch);
    grid-template-rows: repeat(2, 1fr);

	width: 100%;
}

.slot-header > * {
	line-height: 1.1;
}

.time {
	font-weight: 600;
}


.name-part-one {

}

.name-part-two {
	grid-column: span 2 / span 2;
	overflow: hidden;
	text-overflow: ellipsis;
	white-space: nowrap;
}

.slot-footer{
	font-size: 9px;
	
	display: flex;
	align-items: center;
}

.slot-footer__title{
	overflow: hidden;
	text-overflow: ellipsis;
	white-space: nowrap;
	flex: 1;
}

.slot-tools{
	position: absolute;
	z-index: 4;
	top: 0;
	right: 0;
	left: 0;
}

</style>