var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"schedule-date",class:{
	'disabled': !_vm.isCurrentMonth,
	'is-title-only': !_vm.isShowEventSlots,
	'is-selected': _vm.isSelected,
},attrs:{"id":("date-" + (_vm.date.toLocaleDateString()))}},[_c('div',{staticClass:"schedule-date__selector",on:{"click":function($event){_vm.selector ? _vm.selector(_vm.date) : null}}}),_c('div',{staticClass:"schedule-date__title",class:{
		'short' : _vm.titleIsShort,
		'is-today': _vm.isToday(),
		'is-holiday': _vm.isHoliday(_vm.date) || _vm.isSpecialDay(_vm.date),
	}},[_vm._v(" "+_vm._s(_vm.date.getDate())+" ")]),_c('ScheduleDateSlots',{attrs:{"eventSlots":_vm.eventSlots,"isShowEventSlots":_vm.isShowEventSlots,"maxEventSlots":_vm.maxEventSlots,"collapseStep":_vm.collapseStep}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }