function makeDraggable(element, handlers={
	start: () => console.log('start drag!'),
	end: () => console.log('end drag!')
}) {
	if (!element)
		return
	const supportsPointerEvents = 'PointerEvent' in window;

	if (supportsPointerEvents) {
		element.addEventListener('pointerdown', handlers.start);
		element.addEventListener('pointerup', handlers.end);
		element.addEventListener('pointercancel', handlers.end);
	} else {
		element.addEventListener('mousedown', handlers.start);
		element.addEventListener('mouseup', handlers.end);
	}
	element.addEventListener('touchstart', (e) => e.preventDefault());
}

function makeUndraggable(element, handlers={
	start: () => console.log('start drag!'),
	end: () => console.log('end drag!')
}) {
	if (!element)
		return
	const supportsPointerEvents = 'PointerEvent' in window;

	if (supportsPointerEvents) {
		element.removeEventListener('pointerdown', handlers.start);
		element.removeEventListener('pointerup', handlers.end);
		element.removeEventListener('pointercancel', handlers.end);
	} else {
		element.removeEventListener('mousedown', handlers.start);
		element.removeEventListener('mouseup', handlers.end);
	}
	element.removeEventListener('touchstart', (e) => e.preventDefault());
}

export {
	makeDraggable,
	makeUndraggable
}