<template>
	<transition name="show">
		<div class="schedule-date__list" v-if="isShowEventSlots" ref="list" @resize="resizeHandler">
			<div class="schedule-date__list-content" v-if="eventSlots.length > 0">
				<div class="schedule-date__item" 
					v-for="eventSlot in dateEventSlots()"
					:key="`slot-${eventSlot.index}`"

					v-bind:style="{
						background: eventSlot.settings.cellBackgroundColor || 'lavender',
					}">

					<div class="schedule-date__item-title" v-show="collapseStep == 2"
					v-bind:style="{
						color : eventSlot.settings.cellTextColor || 'black',
					}">
						{{ eventSlot.title || `Slot ${eventSlot.index}`}}
					</div>
				</div>
				<div class="schedule-date__item-title additional "
					v-show="collapseStep == 2" 
					v-if="eventSlots.length != dateEventSlots().length">
						+{{ eventSlots.length - maxEventSlots}}
				</div> 
				<!-- <div class="additional-place" 
				v-if="dateEventSlots().length == maxEventSlots - 1 && 
							eventSlots.length != maxEventSlots - 1"></div> -->
			</div>
		</div>
	</transition>
</template>

<script>
export default {
	name: 'ScheduleDateSlots',
	props: {
		eventSlots: {
			type: Array,
			required: true,
		},
		maxEventSlots:{
			type: Number,
			default: 5,
		},
		isShowEventSlots:{
			type: Boolean,
			default: true,
		},
		collapseStep: {
			type: Number,
			default: 1,
		}
	},

	methods: {
		dateEventSlots(){
			let slots = this.eventSlots
			return slots.slice(0, this.maxEventSlots)
		},
		resizeHandler(){
		}
	},
};
</script>

<style scoped>
.schedule-date__list{
	width: 100%;
	background: transparent;
	height: 100%;
}
.schedule-date__list-content{
	border-radius: 4px;
	width: 100%;
	display: flex;
	flex-direction: column;
	gap: 2.5px;
	position: relative;
	background: transparent;
	padding: 0 2px;
	transition: height .3s;
	padding-top: 4px;
	height: 100%;
}


.schedule-date__item{
	border-radius: 4px;
	min-height: 4px;
	height: calc(20% - 4px);
	max-height: 11px;
	width: 100%;
	text-align: start;
	font-size: 8px;
	width: 100%;
	position: relative;
}

.schedule-date__item-title{
	position: absolute;
	top: -1px;
	padding: 0 2px;
	width: 100%;
	text-overflow: ellipsis;
	overflow: hidden;
	white-space: nowrap;
}

.schedule-date__item-title.additional{
	font-size: 8px; 
	height: fit-content;
	text-align: center;
	position: absolute;
	width: fit-content;
	bottom: 0;
	left: 4px;
	top: auto;
}


.show-enter-active {
	animation: .3s enter;
}

.show-leave-active {
	animation: .3s enter reverse;
}

.schedule-date__empty{
	font-size: 10px;
	display: flex;
	justify-content: center;
	align-items: flex-start;
	height: 100%;
}

.additional-place{
	height: 6px;
}

</style>