function setLastPressedSlot(slotId) {
	sessionStorage.setItem("schedule-table-pressed-slot", slotId)
}

function delLastPressedSlot(){
	sessionStorage.removeItem("schedule-table-pressed-slot")
}

function getLastPressedSlot(){
	const value = sessionStorage.getItem("schedule-table-pressed-slot")
	if (!value || Number.isNaN(Number(value)))
		return undefined
	return Number(value)
}

export {
	delLastPressedSlot,
	setLastPressedSlot,
	getLastPressedSlot,
}