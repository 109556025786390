<template>
	<div class="attender-list">
		<div class="attender-list__header">
			<div class="attender-list__header-item">Attender</div>
			<div class="attender-list__header-item">Charging money</div>
		</div>
		<div class="attender-list__items">
			<section>
				<AttenderListItem 
				v-for="(attender, index) of attenderList"
				:key="index"
				@on-open="(value) => openedIndex = value"
				:openedIndex="openedIndex"
				:attender="attender"/>
			</section>
		</div>
	</div>
</template>

<script>
import AttenderListItem from './AttenderListItem.vue';

export default {
	name: 'AttenderList',
	components: {
		AttenderListItem,
	},
	model: {
		prop: 'attenders',
		event: 'update:attenders',
	},

	props: {
		attenders: {
			type: Array,
			required: true,
		}
	},

	computed: {
		attenderList: {
			get() {
				return this.attenders
			},
			set(value) {
				this.$emit('update:attenders', value)
			}
		}
	},

	data() {
		return {
			openedIndex: 0,
		}
	},

	mounted() {
		
	},

	methods: {
		
	},
};
</script>

<style scoped>
.attender-list__header{
	display: flex;
	justify-content: space-between;
	padding-right: 40px;
	padding-left: 40px;
	margin-bottom: 10px;
}
.attender-list__header-item{
	color: #cbcbcb;
	font-size: 12px;
	text-align: center;
}

.attender-list__items{
	overflow: auto;
	overflow-y: auto;
	max-height: 308px;
	box-shadow: 0 0.5em 1em -0.125em rgba(10, 10, 10, 0.1), 0 0px 0 1px rgba(10, 10, 10, 0.02);
}
</style>