<template>
	<div class="calendar-date-title" ref="title">
		<div class="calendar-date-title__item"
		v-for="date in calcDates()"
		:id="`calendar-date-title__item-${date.toLocaleDateString()}`"
		:key="`calendar-date-title__item-${date.toLocaleDateString()}`">
			{{ calcTitle(date) }}
		</div>
	</div>
</template>

<script>
import { getCurrentWeekV2 } from '../../scripts/date';
import { enShortMonths } from '../../scripts/months';

export default {
	name: 'CalendarDateTitle',

	props: {
		selectedDate: {
			type: Date,
			required: true,
		},
		columnCount: {
			type: Number,
			required: true,
		},
	},

	// watch: {
	// 	container(newValue){
	// 		if (!newValue)
	// 			return
	// 		// newValue.addEventListener('scroll', this.onScrollHandler)
	// 	}
	// },

	methods: {

		calcDates(){
			return getCurrentWeekV2(this.selectedDate, this.columnCount)
		},

		calcTitle(date){
			let dateNumber = date.getDate()
			let monthName = enShortMonths[date.getMonth()]
			return `${dateNumber} ${monthName}`
		},
	}
};
</script>

<style scoped>
.calendar-date-title {
	position: sticky;
	top: 0;
	padding-left: 30px;
	padding-right: 16px;

	text-align: center;
	font-size: 11px;
	color: grey;
	z-index: 1;

	display: flex;
	flex-direction: row;
}

.calendar-date-title__item{
	flex: 1;
	transition: all 0.3s;
}

.calendar-date-title__item.selected{
	color: #0075ff;
	font-size: 12px;
	font-weight: 500;
}
</style>