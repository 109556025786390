import CalendarWorkTime from "./calendarWorkTime"

function roundTime(currentHour, currentMinute, roundValue=10){
	let roundedMinute = Math.round(currentMinute / roundValue) * roundValue
	if (roundedMinute == 60){
		currentHour += 1
		roundedMinute = 0
	}
	return {hour: currentHour, minute: roundedMinute}
}

/**
 * Convert pixels posY to the time 
 * @param {Number} posY - slot card position Y in px
 * @param {Number} cellHeight - table cell height (of 1 hour)
 * @param {CalendarWorkTime} workTime - table work time
 * @param {Number} roundValue - value to round the time
 * @returns In success return Object {hour:Number, hour:Number}
 */
function getTimeByPosY(posY, cellHeight, workTime, roundValue=10) {
	const cellPosition = posY / cellHeight
	const currentHour = workTime.range()[Math.trunc(cellPosition)]
	const currentMinute = Math.round((cellPosition % 1) * 60);

	if (currentHour == undefined || currentMinute < 0)
		return undefined
	// if (currentHour == this.workTime.end.getHours() && 
	// 	currentMinute == this.workTime.end.getMinutes())
	// 	return false

	if (currentMinute > 60) {
		currentHour += 1;
		currentMinute = 0;
	}

	return roundTime(currentHour, currentMinute, roundValue)
}

export {
	roundTime,
	getTimeByPosY
}