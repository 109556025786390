<template>
	<div id="schedule-table">
		<BCarouselListMod
			id="schedule-table-carousel"
			v-model="currentDateIndex"
			:data="dates" :items-to-show="1"
			:hasDrag="daysCount == 1"
			@input="inputDateHandler"
			@on-drag-start="syncTableTime"
			@on-drag-end="makeCalendarScrollable">
			<template #item="date" >
				<ScheduleTableFrame
					v-if="workTime"
					:date="date.list"
					:daysCount="daysCount"
					:slotCollection="slotCollection"
					:isEditable="isEditable"
					:editTableCellHeight="editTableCellHeight"
					:workTime="workTime.projection(date.list)"
					:disableHeader="disableHeader"
					:calendar="calendar"
				/>
			</template>
		</BCarouselListMod>
		
		<ScheduleTableDelete
			:show="slotMoveEvent && activity == 'create-slot'"
			:isDeletePreparation="isDeletePreparation"
		/>

		<ScheduleCreateSlot v-if="slotMoveEvent 
				&& isEditable 
				&& (!activity || activity == 'create-slot')" 
			:moveEvent="slotMoveEvent"
			:workTime="workTime"
			:selectedDate="selectedDate"
			:isGridView="isEditable"
			:columnCount="daysCount"
			:isDeletePreparation="isDeletePreparation"
			:calendar="calendar"
			@on-drag-start="setActivityCreateSlot('create-slot')"
			@on-drag-move="checkIntersectionWithBasket"
			@on-drag-end="submitSlot"
		/>
	</div>
</template>

<script>
import { cssVw } from '../../scripts/cssUnits';
import { dateIsPast, isEqualDate } from '../../scripts/date';
import ScheduleTableFrame from './ScheduleTableFrame.vue';
import ScheduleCreateSlot from './ScheduleCreateSlot.vue';
import BCarouselListMod from '../BCarouselListMod.vue';
import CalendarWorkTime from '../../scripts/calendarWorkTime';
import SlotCollection from '../../scripts/slotCollection';
import { scrollByCondition } from '../../scripts/scroll';
import ScheduleTableDelete from './ScheduleTableDelete.vue';
import Calendar from '../../scripts/calendar';
import Slot from '../../scripts/slot';
export default {
	name: "ScheduleTable",
	components: {
		ScheduleTableFrame,
		ScheduleCreateSlot,
		ScheduleTableDelete,
		BCarouselListMod,
	},
	emits: [
		'on-select-date',
		'on-set-activity'
	],
	props: {
		selectedDate: {
			type: Date,
			required: true,
		},
		calendar: {
			type: Calendar,
			required: true,
		},
		slotCollection: {
			type: SlotCollection,
			required: true,
		},
		workTime: {
			type: CalendarWorkTime,
			required: true,
		},
		isEditable: {
			type: Boolean,
			default: true,
		},
		disableHeader: {
			type: Boolean,
			default: false,
		},
		daysCount: {
			type: Number,
			required: true,
		},
		activity: {
			type: String,
		},
	},
	data(){
		return {
			currentDateIndex: 0,
			dates: [],
			isSwipeActive: false,
			currentTimeIsSet: false,
			editTableCellHeight: 54,
			isDeletePreparation: false,
		}
	},
	watch: {


		selectedDate(newValue, oldValue) {
			if (!oldValue)
				return
			if (this.isSwipeActive)
				return 
			if (this.daysCount == 1){
				this.setupDates(newValue)
				this.currentDateIndex = 1

			} else {
				this.dates = [newValue]
				this.currentDateIndex = 0
			}
		},
		workTime(newValue){
			if (!newValue || this.currentTimeIsSet)
				return
			if (this.workTime && this.slotCollection.isLoaded){
				setTimeout(this.setupCalendarScroll, 10)
			}
		}, 
		daysCount(value, oldValue){

			if (value == 1 && oldValue != 1 || value != 1 && oldValue == 1){
				let calendarUid = this.$route.params.calendarUid
				let activeTable = this.activeEditableTable()
				if (activeTable) {
					sessionStorage.setItem(`schedule-table-${calendarUid}-scroll`, activeTable.scrollTop)
				}
			}

			if (value == 1)
				this.currentDateIndex = 1
			else
				this.currentDateIndex = 0

			this.withoutTransition(() => {
				if (value == 1) {
					this.setupDates(this.selectedDate)
				} else {
					this.dates = [this.selectedDate]
				}
				const activeTable = this.activeEditableTable()
				if (activeTable)
					this.$nextTick().then(this.setScrollWithoutTransition)
			})
		},
		'slotCollection.isLoaded': {
			handler(isLoaded){
				if (!isLoaded || this.currentTimeIsSet)
					return
				if ( this.workTime && this.slotCollection.isLoaded){
					this.$nextTick(this.setupCalendarScroll)
				}
			},
			deep: true,
		}
	},

	computed: {
		slotMoveEvent(){
			return this.$store.getters.tableCreateEvent
		},

	},
	created() {
		this.setupDates(this.selectedDate)
	},

	mounted() {
		if (this.daysCount == 1)
			this.currentDateIndex = 1
		else
			this.currentDateIndex = 0
		this.withoutTransition()
		.then(this.setScrollWithoutTransition)
	},

	methods: {
		setActivityCreateSlot(value){
			if (value && this.activity)
				return	
			this.$emit('on-set-activity', value)
		},
		setScrollWithoutTransition(){
			let calendarUid = this.$route.params.calendarUid
			let savedTableScroll = sessionStorage.getItem(`schedule-table-${calendarUid}-scroll`)
			sessionStorage.removeItem(`schedule-table-${calendarUid}-scroll`)
			if (!savedTableScroll)
				return
			let scrollTop = Number(savedTableScroll)
			let activeTable = this.activeEditableTable()
			if (!activeTable)
				return

			activeTable.scrollTop = scrollTop
			this.currentTimeIsSet = true
		},	
		submitSlot(slotData){
			this.setActivityCreateSlot(null)

			if (!slotData)
				return

			const {hour, minute, date} = slotData

			if (!this.isEditable)
				return

			const slotStartDate = new Date(date)
			slotStartDate.setHours(hour, minute, 0, 0)
			if (this.calendar.IsCommercial && dateIsPast(slotStartDate))
				return

			const userId = this.$store.getters.userId
			const newSlot = new Slot(slotStartDate, 60, {
				id : this.addedSlots * -1,
				isQuestionSlot: true,
				hostId: userId,
			})
			const browserTZ = this.$store.getters.browserTimezone
			const calendarTZ = this.$store.getters.calendarTimezone
			const isProCalendar = this.calendar.IsCommercial

			this.slotCollection.addQuestionSlot(newSlot)
			this.slotCollection.submitSlot(newSlot, browserTZ, calendarTZ, isProCalendar)
			this.addedSlots += 1
		},
		setupCalendarScroll(){
			let isToday = isEqualDate(this.selectedDate, new Date())
			let firstNotStartedSlot = this.slotCollection.fullSlotList({sortByTime: true})
			.filter(slot => isEqualDate(slot.period.start.date, this.selectedDate))
			.find(slot => {
				return slot.period.start.date > new Date()
			})
			
			let activeCarouselSlide = this.$el.querySelector('.carousel-slide.is-active')
			if (!activeCarouselSlide)
				return
			let activeCalendarTable = activeCarouselSlide.querySelector('.calendar-v2')
			if (!activeCalendarTable)
				return

			let targetPosY
			if (firstNotStartedSlot) {
				targetPosY = this.getSlotPosition(firstNotStartedSlot)
				targetPosY += this.getSlotHeight(firstNotStartedSlot) / 2
			} else if (isToday) {
				targetPosY = this.getRedLinePosition(activeCalendarTable)
			} else {
				this.currentTimeIsSet = true
				return
			}

			if (targetPosY == null)
				return 

			let tableHeight = activeCalendarTable.clientHeight
			let tableScroll = activeCalendarTable.scrollTop
			
			let targetScroll = targetPosY - tableHeight/2

			// if (targetPosY < tableScroll || targetPosY > tableScroll + tableHeight) {
			if (Math.abs(targetScroll - tableScroll) > 10) {
				let speed = 10
				let scrollCondition = (target) => {
					return target && speed > 0 &&
						Math.abs(Math.round(target.scrollTop + tableHeight) - target.scrollHeight) > 2 &&
						target.scrollTop < targetScroll
				}
				scrollByCondition(activeCalendarTable, () => speed, scrollCondition, {
					onScroll: (target) => {
						let currentScroll = target.scrollTop
						if (Math.abs(targetScroll - currentScroll) < 100)
							speed = Math.abs(targetScroll - currentScroll) / 10
					}
				})
			}
			this.currentTimeIsSet = true
		},

		activeEditableTable(){

			let activeCarouselSlide = this.$el.querySelector('.carousel-slide.is-active')
			if (!activeCarouselSlide)
				return null
			let activeCalendarTable = activeCarouselSlide.querySelector('.calendar-v2')
			if (!activeCalendarTable)
				return null
			return activeCalendarTable
		},

		getRedLinePosition(table){
			let tableRedLine = table.querySelector('.red__line')
			if (!tableRedLine)
				return null
			let tableRedLinePosY = tableRedLine.style["top"].replace('px', '')
			if (Number(tableRedLinePosY) == NaN)
				return null
			return Number(tableRedLinePosY)
		},

		getSlotHeight(slot) {
			return Math.floor(this.editTableCellHeight * slot.period.duration / 60)
		},

		getSlotPosition(slot) {
			let {hour, minute} = this.workTime.startTime()
			let workStartAlign = this.topPositionByTime(hour, minute)
			let slotStart = {
				hour: slot.period.start.date.getHours(),
				minute: slot.period.start.date.getMinutes(),
			}
			let relativeTop = this.topPositionByTime(slotStart.hour, slotStart.minute) 
			return relativeTop - workStartAlign
		},

		topPositionByTime(hour, minute){
			let topPosition = 0
			topPosition += hour * this.editTableCellHeight
			topPosition += minute * this.editTableCellHeight / 60
			return Math.floor(topPosition)
		},

		inputDateHandler(newValue){
			this.isSwipeActive = true
			if (newValue == this.dates.length - 1) {
				this.pushDateRight()
			} else if (newValue == 0 ) {
				newValue = 1
				setTimeout(() => {
					this.pushDateLeft()
					this.withoutTransition(() => {
						this.currentDateIndex = newValue
					})
					this.$emit('on-select-date', this.dates[newValue], saveCalendar)
					setTimeout(() => this.isSwipeActive = false, 50)
				}, 50);
				return
			}
			let saveCalendar = false
			this.$emit('on-select-date', this.dates[newValue], saveCalendar)
			setTimeout(() => this.isSwipeActive = false, 50)
		},
		withoutTransition(action) {
			return new Promise((resolve, _) => {
				let scheduleTable = document.getElementById('schedule-table-carousel');
				let scheduleTableSlides = scheduleTable.getElementsByClassName('carousel-slides')[0];

				scheduleTableSlides.style.transform = `translateX(-${this.currentDateIndex*100*cssVw}px)`
				scheduleTableSlides.style.transition = 'none';
				if (action)
					action()
				setTimeout(() => {
					scheduleTableSlides.style.transition = null;
					resolve()
				}, 0);
			})
		},
		pushDateRight() {
			let date = new Date(this.dates[this.dates.length - 1]);
			date.setDate(date.getDate() + 1);
			this.dates.push(date);
		},
		pushDateLeft() {
			let dates = [...this.dates]
			let date = new Date(this.dates[0]);
			date.setDate(date.getDate() - 1);
			dates.unshift(date);
			this.dates = dates;
		},
		setupDates(date){

			if (this.daysCount != 1) {
				this.dates = [date]
				return
			}

			let dates = []
			
			let prevDate = new Date(date)
			prevDate.setDate(prevDate.getDate() - 1)
			dates.push(prevDate)

			dates.push(date)

			let nextDate = new Date(date)
			nextDate.setDate(nextDate.getDate() + 1)
			dates.push(nextDate)
			this.dates = dates
		},
		syncTableTime(){
			if (!this.isEditable)
				return
			let allCarouselSlides = Array.from(this.$el.querySelectorAll('.carousel-slide'))
			let activeCarouselSlide = allCarouselSlides.find(slide => {
				return slide.classList.contains('is-active')
			})
			if (!activeCarouselSlide)
				return
			
			let anotherCarouselSlides = allCarouselSlides.filter(slide => !slide.classList.contains('is-active'))
			let activeCalendarTable = activeCarouselSlide.querySelector('.calendar-v2')
			if (!activeCalendarTable)
				return
			anotherCarouselSlides
				.map(slide => slide.querySelector('.calendar-v2'))
				.forEach(table => {
					if (table.scrollTop == activeCalendarTable.scrollTop)
						return
					table.scrollTop = activeCalendarTable.scrollTop
					table.classList.add('no-scroll')
				})
		},
		makeCalendarScrollable(){
			Array.from(this.$el.querySelectorAll('.carousel-slide'))
				.map(slide => slide.querySelector('.calendar-v2'))
				.forEach(table => table.classList.remove('no-scroll'))	
		},
		checkIntersectionWithBasket({posY}){
			const newSlotPosY = posY
			const deleteBasket = this.$el.querySelector('.delete-slot')
			if (!deleteBasket)
				return
			const deleteBasketRect = deleteBasket.getBoundingClientRect()
			const containerRect = this.$el.getBoundingClientRect()
			const deleteBasketPosY = deleteBasketRect.top - containerRect.top
			const difference = Math.abs(newSlotPosY - deleteBasketPosY)
			this.isDeletePreparation = difference < this.editTableCellHeight * 0.9
		},
	},
}
</script>

<style>

#schedule-table {
	position: relative;
	height: 100%;
	width: 100%;
}

#schedule-table-carousel {
	height: 100%;
	max-height: 100%;
}


.calendar-schedule {
	height: 100%;
	overflow: hidden;
	display: flex;
	flex-direction: column;
}

.table-varnish {
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	z-index: 2;
}
</style>