<template>
	<div class="schedule-slot-form">

		<!-- Slot Form -->

		<div class="slot-form">
			
			<!-- Slot Header -->

			<div class="slot-header">
				<div class="slot-start">
					{{ slotStartTime() }}
				</div>
				<div class="slot-color" v-bind:style="{
					background,
				}"></div>
				<div class="slot-title" 
				@click="focusOnTitle" 
				v-click-outside="blurTitle">
					<input
						ref="title" 
						type="text" 
						v-model="title" 
						placeholder="Enter the title"
						v-on:keyup.enter="blurTitle"
					>
				</div>
			</div>

			<!-- Slot Content -->

			<div class="slot-content">
				<div class="slot-duration">
					{{ slotDurationTime() }}
				</div>
			</div>
		</div>

		<!-- Slot Buttons -->

		<div class="slot-buttons">
			<b-button size="is-small" type="is-info" :disabled="!isAbleToSubmit" @click="submitHandler">
				Save
			</b-button>
			<b-button size="is-small" type="is-danger is-light" @click="removeHandler">
				Cancel
			</b-button>
		</div>
	</div>
</template>

<script>
import { getTimeString } from '../../scripts/date';
import Slot from '../../scripts/slot';
import SlotCollection from '../../scripts/slotCollection';
export default {
	name: 'ScheduleSlotForm',

	props: {
		questionSlot: {
			type: Slot,
			required: true,
		},
		slotCollection: {
			type: SlotCollection,
			required: true,
		}
	},

	emits: [
		'on-change-title',
		'on-slot-submit',
		'on-slot-remove',
	],

	data() {
		return {
			enterTitleIsOpen: false,
		};
	},

	directives: {
		'click-outside' : {
			bind: function (el, binding, vnode) {
				el.clickOutsideEvent = function (event) {
					// here I check that click was outside the el and his children
					if (!(el == event.target || el.contains(event.target))) {
						// and if it did, call method provided in attribute value
						vnode.context[binding.expression](event);
					}
				};
				document.body.addEventListener('click', el.clickOutsideEvent)
			},
			unbind: function (el) {
				document.body.removeEventListener('click', el.clickOutsideEvent)
			},
		}
	},

	computed: {
		background(){
			return this.questionSlot.color || 'lavender'
		},
		title:{
			get(){
				return this.questionSlot.title
			},
			set(value){
				this.questionSlot.title = value
			}
		},
		isAbleToSubmit(){
			return this.questionSlot.title != ""
		},
	},

	methods: {
		slotDurationTime(){
			return `${getTimeString(this.questionSlot.period.start.date)} - ${getTimeString(this.questionSlot.period.end.date)}`
		},
		slotStartTime(slot){
			return getTimeString(this.questionSlot.period.start.date)
		},

		submitHandler(){
            let browserTimezone = this.$store.getters.browserTimezone
            let calendarTimezone = this.$store.getters.calendarTimezone
			if (!browserTimezone || !calendarTimezone)
				return 
			this.slotCollection.submitSlot(this.questionSlot, browserTimezone, calendarTimezone)
		},
		removeHandler(){
			this.slotCollection.removeQuestionSlot(this.questionSlot)
		},

		blurTitle(){
			if (this.titleIsFocused()) {
				this.$refs.title.blur()
			}
		},
	
		focusOnTitle(){
			if (!this.titleIsFocused()) {
				this.$refs.title.focus()
			}
		},
		titleIsFocused(){
			return this.$refs.title === document.activeElement
		},
	},
};
</script>

<style scoped>
.schedule-slot-form {
	display: grid;
	grid-template-columns: calc(100% - 120px - 10px) 120px;
	column-gap:10px;
}

.schedule-slot-form.is-focused {
	position: relative;
	z-index: 3;
}

.slot-header{
	display: flex;
	flex-direction: row;
	gap: 8px;
	height: fit-content;
	display: flex;
	align-items: center;
	height: 24px;
}


.slot-start{
	font-size: 12px;
	font-weight: bold;
	padding-right: 6px;
	width: 38px;
}
.slot-color{
	height: 18px;
	width: 4px;
	border-radius: 5px;
}

.slot-duration{
	font-size: 11px;
	font-weight: 500;
	color: #9f9f9f;
	padding-left: 58px;
}

.slot-title{
	font-weight: 500;
	width: calc(100% - 16px - 4px - 38px);
}

.slot-title > input {
	width: 100%;
	border: none;
	font-size: 16px;
	border-bottom: 1px solid grey;
}

.slot-title input:placeholder-shown {
   font-style: italic;
}

.slot-title > input:focus {
	outline: none;
	border-bottom: 1px solid black;
}

.slot-buttons{
	display: flex;
	flex-direction: row;
	align-items: center;
	gap: 10px;
}

.slot-title-placeholder{
	font-style: italic;
}
</style>