<template>
	<div class="schedule-month">
		<Week v-for="week, w_index in month"
			v-model="date"
			:key="`week-${name}-${w_index}`"
			:week="week"
			:slotCollection="slotCollection"
			:checkCurrentMonth="isCurrentMonth"
			:collapseStep="collapseStep"
			:daysCount="daysCount"
		/>
	</div>
</template>

<script>
import { enLongMonths } from '../../../scripts/months';
import Week from './Week.vue';
import SlotCollection from '../../../scripts/slotCollection';

export default {
	name: 'Month',
	components: {
		Week
	},
	model: {
		prop: 'selectedDate',
		event: 'update:selectedDate'
	},
	props: {
		month: {
			type: Object,
			required: true,
		},
		selectedDate: {
			type: Date,
			required: true,
		},
		slotCollection: {
			type: SlotCollection,
			required: true,
		},
		isActive: {
			type: Boolean,
			default: false,
		},
		collapseStep: {
			type: Number,
			default: 1,
		},
		daysCount: {
			type: Number,
			default: 7,
		}
	},

	mounted() {
		this.$el.addEventListener('pointerdown', this.pointerDownHandler)
		this.$el.addEventListener('pointermove', this.pointerMoveHandler)
		this.$el.addEventListener('pointerup', this.pointerUpHandler)
	},

	beforeDestroy() {
		this.$el.removeEventListener('pointerdown', this.pointerDownHandler)
		this.$el.removeEventListener('pointermove', this.pointerMoveHandler)
		this.$el.removeEventListener('pointerup', this.pointerUpHandler)
	},
	
	computed: {
		name(){
			let now = new Date()
			let monthDate = this.month[1][0];

			if (now.getFullYear() == this.month[1][0].getFullYear())
				return enLongMonths[monthDate.getMonth()]

            let monthName = enLongMonths[monthDate.getMonth()];
            let yearName = monthDate.getFullYear();		
            return `${monthName} ${yearName}`;
		},
		date: {
			get(){
				return this.selectedDate
			},
			set(value){
				this.$emit('update:selectedDate', value)
			}
		},
	},

	methods: {
		isCurrentMonth(date){
			return date.getMonth() == this.month[1][0].getMonth();
		},

		
		pointerDownHandler(ev){
			this.lastPointerY = ev.clientY
			this.lastPointerX = ev.clientX
		},

		pointerMoveHandler(ev){
			let posX = ev.clientX
			let posY = ev.clientY
			if (posX <= this.lastPointerX + 15 && posX >= this.lastPointerX - 15) {
				this.calendarIsScrollable = false
			} else {
				this.calendarIsScrollable = true
			}
		},

		pointerUpHandler(ev){
			this.calendarIsScrollable = true
		},
	},
};
</script>

<style  scoped>
.schedule-month{
	display: flex;
	flex-direction: column;
	width: 100%;
	height: 100%;
}
</style>