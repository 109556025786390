<template>
	<SlotCard
		:eventSlot="eventSlot"
		:slotContainer="slotContainer"
		:workTime="workTime"
		:columnCount="columnCount"
		:cellHeight="cellHeight"
		:slotCollection="slotCollection"
	>
		<template v-slot:title>
			<div>{{ eventSlot.settings.title }}</div>
		</template>
	</SlotCard>
</template>

<script>

import SlotCollection from '../../scripts/slotCollection';
import SlotCard from './slot-card/SlotCard.vue';

export default {
	name: 'QuestionSlotCard',
	components: {
		SlotCard,
	},
	props: {
		...SlotCard.props,
		slotCollection: {
			type: SlotCollection,
			required: true,
		},
	},
	directives : {
		'click-outside' : {
			bind: function (el, binding, vnode) {
				console.log('bind :>> ', el);
				el.clickOutsideEvent = function (event) {
					if (!(el == event.target || el.contains(event.target))) {
						vnode.context[binding.expression](event);
					}
				};
				document.addEventListener('mousedown', el.clickOutsideEvent)
			},
			unbind: function (el) {
				document.removeEventListener('mousedown', el.clickOutsideEvent)
			},
		}
	},
	computed: {
		isAbleToSubmit(){
			return this.eventSlot.title.length > 0;
		},
		inputWidth(){
			let buttons = this.$refs.buttons
			if (!buttons)
				return '100%'
			let buttonsSizes = buttons.getBoundingClientRect()
			return `calc(100% - ${buttonsSizes.width}px - 10px - 10px - 30px)`
		}
	},
	methods: {
		focusHandler(){
			this.$refs["title"].focus()
		},
		unfocusHandler(){
			this.$refs.title.blur()
		},
		submitHandler(){
            let browserTimezone = this.$store.getters.browserTimezone
            let calendarTimezone = this.$store.getters.calendarTimezone
			if (!browserTimezone || !calendarTimezone)
				return 
			this.slotCollection.submitSlot(this.eventSlot, browserTimezone, calendarTimezone)
		},
		removeHandler(){
			this.slotCollection.removeQuestionSlot(this.eventSlot)
		},
	}
};
</script>

<style scoped>
input {
	width: 100%;
	border: none;
	font-size: 16px;
	border-bottom: 1px solid grey;
	border-radius: 2px;
	padding: 2px 4px;
	resize: none;
	box-sizing: border-box;
}

input:focus {
	outline: none;
	border-bottom: 1px solid rgb(162, 162, 189);
}

.button-container{
	display: flex;
	flex-direction: row;
	gap: 10px;
	height: 100%;
	display: flex;
	align-items: center;
}

.title-cont{
	width: calc(100% - 10px - 30px);
	height: 100%;
	display: flex;
	align-items: center;
}

.calendar-slot-form{
	top: 5px;
	bottom: 5px;
	left: 40px;
	right: 7px;
	position: absolute;
	z-index: 2;
	font-size: 12px;
	display: flex;
	flex-direction: row;
	justify-content: space-between;
	gap: 10px;
	transition: border-radius 0.1s;
	justify-content: flex-end;
}
</style>