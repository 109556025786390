<template>
	<ModalTemplate v-model="isShow" 
	name="slot-confirmation-modal" 
	:closer="closeHandler"
	queryName="m_scm"
	:queryValue="queryValue">
		<div class="modal-window">
			<div class="modal__header">
				{{ modalTitle }}
			</div>
			<div class="modal__content" v-if="eventSlot">

				<div class="slot__about">
					<div class="slot__about-header">
						<div class="slot__about-time">{{ slotTime }}</div>
						<div class="slot__about-title">{{ eventSlot.title }}</div>
					</div>
					<div class="slot__about-description">
						{{eventSlot.settings.description}}
					</div>
				</div>

				<div class="slot__confirmation">
					<AttendeeList v-if="chargingRule == 2 && attenders" v-model="attenders"/>
				</div>

				<div class="slot__buttons">
					<b-button type="is-info" expanded outlined @click="closeHandler">
						Cancel
					</b-button>
					<b-button type="is-info" expanded @click="submitHandler">
						Save
					</b-button>
				</div>
			</div>
		</div>
	</ModalTemplate>
</template>

<script>
import { globalBus } from '../../../GlobalEventBus';
import { AttenderConfirmation } from '../../../scripts/attender';
import { pair } from '../../../scripts/date';
import Slot from '../../../scripts/slot';
import ModalTemplate from '../ModalTemplate.vue';
import AttendeeList from './AttenderList.vue';

export default {
	name: 'SlotConfirmationModal',
	components: {
		ModalTemplate,
		AttendeeList
	},
	props: {
		initSlot: {
			type: Slot,
		},
	},
	computed: {
		calendar(){
			return this.$store.getters.currentCalendar()
		},

		calendarUid(){
			return this.$route.params.calendarUid
		},
		
		chargingRule(){
			if (!this.eventSlot || !this.eventSlot.settings.regular)
				return null
			return this.eventSlot.settings.regular.chargingRule
		},

		userHaveAccess(){
			if (!this.calendar)
				return false
			return this.calendar.isEditable
		},
		queryValue(){
			if (!this.eventSlot)
				return null
			return this.eventSlot.index.toString()
		},
		modalTitle(){
			if (!this.eventSlot || !this.eventSlot.settings.regular)
				return null
			const chargingRule = this.eventSlot.settings.regular.chargingRule 
			if (chargingRule == 2) {
				return 'Attendance confirmation'
			} else if (chargingRule == 3) {
				return 'Attendance confirmation'
			}
			return ''
		},
		slotTime(){
			if (!this.eventSlot)
				return null
			const startDate = this.eventSlot.period.start.date
			const startHour = pair(startDate.getHours())
			const startMinute = pair(startDate.getMinutes())
			return `${startHour}:${startMinute}`
		},
	},

	created() {
		globalBus.$on('attendee-confirmation', 'show', (slot) => {
			if (!this.userHaveAccess)
				return
			this.setSlot(slot)
			this.showHandler()
		})
	},
	
	watch: {
		userHaveAccess(){
			this.setupEventSlot()			
		},
		initSlot(newValue, oldValue){
			if (oldValue)
				return
			this.setupEventSlot()			
		}
	},

	data() {
		return {
			isShow: false,
			isWaitResponse: false,
			eventSlot: null,
			attenders: null,
		};
	},

	methods: {
		closeHandler(){
			this.delSlot()
			this.isShow = false
		},
		showHandler(){
			// globalBus.$emit("static-menu", "update-visible", {
			// 	visible: false
			// })
			// setTimeout(() => this.isShow = true, 300)
			this.isShow = true
		},
		setupEventSlot(){
			if (this.initSlot && this.userHaveAccess) {
				this.setSlot(this.initSlot)
				this.showHandler()
			}
		},
		delSlot(){
			this.eventSlot = null
			this.attenders = null
		},
		setSlot(slot){
			if (!slot && !this.eventSlot)
				return
			if (slot && !slot.settings.regular)
				return

			const chargingRule = slot.settings.regular.chargingRule
			if (chargingRule == 2) {
				this.setupAttenders()
			} else if (chargingRule == 3) {
				// this.attenders = slot.attenders
			}
			this.eventSlot = slot
		},
		setupAttenders(){
			this.attenders = [1, 2, 3, 4, 5, 6].map((id) => {
				return new AttenderConfirmation({
					userId: id,
					fullName: `User ${id}`,
					price: 100,
				})
			})
			this.attenders[0].price = null
		},
		submitHandler(){
			this.closeHandler()
		}
	},
};
</script>

<style scoped>
.modal-window {
	background: white;
    position: absolute;
	right: 20px;
	border-radius: 8px;
	left: 20px;
	top:50%;
	bottom:50%;
	transform:translate(0, -50%);
	height: fit-content;
}

.modal__header{
    display: flex;
    /* justify-content: center; */
	padding: 12px;
    align-items: center;
    height: 40px;
    width: 100%;
    font-weight: 600;
    border-bottom: 2px solid rgb(240, 240, 240);
}

.modal__content{
	padding: 16px;
	overflow-y: auto;
	max-height: 100%;

	display: flex;
	flex-direction: column;
	gap: 16px;
}

.slot__about{
	display: flex;
	flex-direction: column;
	gap: 16px;
}

.slot__about-header{
	display: flex;
	gap: 16px;
}

.slot__about-time{
	font-size: 32px;
	font-weight: 600;
}

.slot__about-title{
	flex: 1;
	font-size: 16px;
	overflow: hidden;
	text-overflow: ellipsis;
	display: -webkit-box;
	-webkit-line-clamp: 2; 
			line-clamp: 2;
	-webkit-box-orient: vertical;
	line-height: 1.3;
	align-self: center;
}

.slot__about-description{
	overflow: hidden;
	line-height: 1.3;
	text-overflow: ellipsis;
	display: -webkit-box;
	-webkit-line-clamp: 2; 
			line-clamp: 2;
	-webkit-box-orient: vertical;
	font-size: 13px;
	color: rgb(120, 120, 120);
}

.slot__confirmation{
	flex: 1;
}

.slot__buttons{
	display: flex;
	gap: 16px
}
</style>