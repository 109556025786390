<template>
	<div class="calendar-schedule-page" v-if="calendar && !calendar.isRestricted">
		 
		<!-- Date calendar -->

		<Calendar v-model="daysCount"
			:months="months"
			:selectedDate="selectedDate"
			:slotCollection="slotCollection"
			:workTime="workTime"
			:activity="activity"
			@on-swipe="inputMonthHandler"
			@on-set-activity="(value) => activity = value"
			@on-select-date="selectDateHandler"
		/>

		<!-- Schedule -->
		<ScheduleTable
			:daysCount="daysCount"
			:selectedDate="selectedDate"
			:slotCollection="slotCollection"
			:workTime="workTime"
			:isEditable="isEditable"
			:activity="activity"
			:calendar="calendar"
			@on-select-date="selectDateHandler"
			@on-set-activity="(value) => activity = value"
		/>

		<SlotConfirmationModal
			:initSlot="openedConfirmationSlot"
		/>
	</div>
</template>

<script>
import { makeMonthByDate } from '../scripts/week';
import YYYYMMDD, { convertTZ, getCurrentWeekV2, isEqualDate, nextDate, smartDateSelect, weekStart } from '../scripts/date';
import ScheduleTable from '../components/schedule/ScheduleTable.vue';
import Calendar from '../components/schedule/calendar/Calendar.vue';
import SlotCollection from '../scripts/slotCollection';
import CalendarWorkTime from '../scripts/calendarWorkTime';
import { globalBus } from '../GlobalEventBus.js';
import SlotConfirmationModal from '../components/modals/slot-confirmation-modal/SlotConfirmationModal.vue';

export default {
    name: 'CalendarSchedule',
	components: {
		ScheduleTable,
		Calendar,
		SlotConfirmationModal,
	},
    data() {
        return {
            selectedDate: null,
            months: [],
			monthTakeWaitList: [],
			dates: [],
			slots: {},
			slotCollection: null,
			calendarIsVisible: true,
			calendarAnimation: null,
			activity: null,
			scrollTop: 0,
			daysCount: null,

			openedConfirmationSlot: null,
        };
    },
	computed: {
		calendarUid(){
			return this.$route.params.calendarUid
		},

		calendar(){
			if (!this.calendarUid)
				return undefined
			return this.$store.getters.getCalendarByUid(this.calendarUid)
		},

		calendarTimezone(){
			if (!this.calendar)
				return undefined
			const timezoneId = this.calendar.timezoneId
			return this.$store.getters.getTimezoneByID(timezoneId)
		},

		browserTimezone(){
            return this.$store.getters.browserTimezone
		},

		workTime(){
			return !this.calendar ?
				new CalendarWorkTime(new Date(), new Date()) : this.calendar.workTime
		},

		isEditable(){
			return !this.calendar ?
				false : this.calendar.isEditable
		},
	},
	beforeRouteLeave (to, from, next) {

		globalBus.$off(`calendar-schedule-${this.calendarUid}`)

		let isSlotPage = to.name == 'slot-edit' || to.name == 'slot-attend'

		if (from.name == 'calendar-schedule') {
			this.saveCurrentScrollPosition()
		}
		this.saveCurrentSettings(to.name)
		next()
	},
    created() {
        // Setup initial data about months and orders
		this.setupQueryVariables()
		this.setupMonths(this.selectedDate)

		let calendarUid = this.$route.params.calendarUid
		this.slotCollection = new SlotCollection(calendarUid, [])

		globalBus.$on(`calendar-schedule-${calendarUid}`, 'add-slot', (slots) => {
			this.slotCollection.addSlots(slots)
		})

		this.setupSlots({currentMonthIndex: 1})
	},
	beforeDestroy() {
		let calendarUid = this.$route.params.calendarUid
		globalBus.$off(`calendar-schedule-${calendarUid}`)
	},

	watch: {
		selectedDate(newDate, oldDate) {
			if (!oldDate || newDate == oldDate)
				return

			let query = {
				dt: YYYYMMDD(newDate),
			}

			this.updateUrl(query)
		},
		daysCount(newDays, oldDays) {
			if (!oldDays || newDays == oldDays)
				return

			let query = {
				dr: newDays,
				dt: YYYYMMDD(this.selectedDate),
			}

			if (newDays == 7)
				this.selectedDate = weekStart(this.selectedDate)
			else if (newDays > oldDays) {
				const selectedRangeStart = new Date(this.selectedDate)
				const selectedRangeEnd = new Date(this.selectedDate)
				selectedRangeStart.setDate(selectedRangeStart.getDate() - Math.floor(this.daysCount / 2))
				selectedRangeEnd.setDate(selectedRangeEnd.getDate() + Math.floor(this.daysCount / 2))

				const selectedWeekStart = weekStart(this.selectedDate)
				const selectedWeek = getCurrentWeekV2(selectedWeekStart, 7)

				while (selectedRangeStart < selectedWeek[0]){
					selectedRangeStart.setDate(selectedRangeStart.getDate() + 1)
					selectedRangeEnd.setDate(selectedRangeEnd.getDate() + 1)
				}
				while (selectedRangeEnd > selectedWeek[selectedWeek.length - 1]) {
					selectedRangeStart.setDate(selectedRangeStart.getDate() - 1)
					selectedRangeEnd.setDate(selectedRangeEnd.getDate() - 1)
				}
				this.selectedDate = selectedRangeStart
			}

			this.updateUrl(query)
		},
		'$route.params.calendarUid': {
			handler: function(newCalendarUid, oldCalendarUid) {
				if (newCalendarUid == oldCalendarUid)
					return

				let date = new Date()
				if (this.$route.query.dt)
					date = new Date(this.$route.query.dt)

				let calendarUid = newCalendarUid
				if (this.months.length == 0) {
					this.setupMonths(date)
				}
				
				if (this.slotCollection && this.slotCollection.calendarUid != calendarUid){
					this.slotCollection = new SlotCollection(calendarUid, [])
					this.setupSlots({currentMonthIndex: 1})	
				}
			},
			deep: true,
			immediate: true
		},
	},

    methods: {
		saveCurrentScrollPosition(){
			let table = document.getElementById('schedule-table')
			let activeCarouselSlide = table.querySelector('.carousel-slide.is-active')

			if (!activeCarouselSlide)
				return
			let activeCalendarTable = activeCarouselSlide.querySelector('.calendar-v2')
			if (!activeCalendarTable)
				return
			let scroll = activeCalendarTable.scrollTop
			sessionStorage.setItem(`schedule-table-${this.calendarUid}-scroll`, scroll)
		},

		saveCurrentSettings(newRouteName){
			let prefix 
			
			if (newRouteName == 'slot-edit') {
				prefix = 'edit'
			} else if (newRouteName == 'slot-attend') {
				prefix = 'attend'
			} else if (newRouteName == 'calendar-settings-attenders-menu') {
				prefix = 'attenders'
			} else if (newRouteName == 'calendar-settings-menu') {
				prefix = 'settings'
			} else if (newRouteName == 'calendar-settings-host-menu') {
				prefix = 'hosts'
			} else if (newRouteName == 'account-settings') {
				prefix = 'account'
			} else {
				return
			}
			
			const currentPageData = {
				name: 'calendar-schedule',
				params: this.$route.params,
				query: this.$route.query
			}
			localStorage.setItem(`originPage-${prefix}`, JSON.stringify(currentPageData))
		},

		inputMonthHandler(newValue){
			if (newValue == this.months.length - 1)
				this.pushMonthRight()
			if (newValue == 0) {
				newValue = 1
				this.pushMonthLeft()
			}
			this.setupSlots({currentMonthIndex: newValue})
		},

		setupQueryVariables(){

			// Setup selected date
			let date = this.$route.query.dt ?
				new Date(this.$route.query.dt) :
			 	new Date()

			// Setup days count
			if (this.$route.query.dr && !!Number(this.$route.query.dr))
				this.daysCount = parseInt(this.$route.query.dr)
			else 
				this.daysCount = 1

			this.selectedDate = smartDateSelect(date, this.daysCount)
		},

		setupSlots({currentMonthIndex, start, end}){
			if (currentMonthIndex != undefined) {
				let currentMonth = this.months[currentMonthIndex]
				start = currentMonth[0][0]
				end = currentMonth[Object.values(currentMonth).length - 1][6]
			}

			if (!this.browserTimezone || !this.calendarTimezone)
				return setTimeout(() => this.setupSlots({currentMonthIndex, start, end}), 100)

			const browserTz = this.browserTimezone
			const calendarTz = this.calendarTimezone
			const from = convertTZ(start, this.calendarTimezone, this.browserTimezone)
			const to = convertTZ(end, this.calendarTimezone, this.browserTimezone)

			if (this.slotCollection.isLoading 
			|| this.slotCollection.loadingParamsIsEqual(from, to, browserTz, calendarTz))
				return
			console.log('new loading params!');
			this.slotCollection.updateSlots(from, to, browserTz, calendarTz)
			.then(() => {
				this.setupConfirmationSlot() 
			})
		},

		setupConfirmationSlot(){
			if (this.openedConfirmationSlot)
				return
			const confirmationSlotId = this.$route.query['m_scm']
			if (!confirmationSlotId || Number(confirmationSlotId) == NaN)
				return
			const slotId = Number(confirmationSlotId)
			const slot = this.slotCollection.getById(slotId)
			if (!slot)
				return
			this.openedConfirmationSlot = slot
		},

		isInCalendar(date){
			return !!this.months.find(month => {
				const monthIndex = month[0][6].getMonth()
				return date.getMonth() == monthIndex
			})
		},	

        setupMonths(date) {
            let prevMonthDate = new Date(date);
            let months = [];
            prevMonthDate.setMonth(prevMonthDate.getMonth() - 1);
			prevMonthDate.setDate(1)
            months[0] = makeMonthByDate(prevMonthDate);

            let currMonthDate = new Date(date);
            months[1] = makeMonthByDate(currMonthDate);
            
			let nextMonthDate = new Date(date);
            nextMonthDate.setDate(1)
			nextMonthDate.setMonth(nextMonthDate.getMonth() + 1);
            months[2] = makeMonthByDate(nextMonthDate);
            
			this.months = months;
        },
        pushMonthRight() {
            let monthDate = new Date(this.months[this.months.length - 1][1][0]);
            monthDate.setMonth(monthDate.getMonth() + 1, 1);
           	
			this.months.push(makeMonthByDate(monthDate));
        },
        pushMonthLeft() {
			let monthDate = new Date(this.months[0][1][0]);
            monthDate.setMonth(monthDate.getMonth() - 1, 1);
			
			this.months.unshift(makeMonthByDate(monthDate));
		},
		selectDateHandler(newDate, isUpdateCalendar=true){


			let firstMonthDate = this.months[1][1][0]
			let lastMonthDate = this.months[this.months.length - 2][1][0]

			if (!isUpdateCalendar) {
				this.selectedDate = newDate
				return
			}


			if (!this.isInCalendar(newDate)) {
				this.setupMonths(newDate)
				this.selectedDate = newDate
				return
			}

			let firstDate = new Date(
				firstMonthDate.getFullYear(), 
				firstMonthDate.getMonth(), 
				1
			)

			let lastDate = new Date(
				lastMonthDate.getFullYear(), 
				lastMonthDate.getMonth() + 1, 
				0
			)
			
			if (newDate.getTime() < firstDate.getTime()) {
				this.inputMonthHandler(0)
			}
			if (newDate.getTime() > lastDate.getTime()) {
				this.inputMonthHandler(this.months.length - 1)
			}

			this.selectedDate = newDate

		},

		updateUrl(query){
			if (this.$route.query.dt != undefined && query.dt == undefined)
				query.dt = this.$route.query.tb

			if (this.$route.query.tb != undefined && query.tb == undefined)
				query.tb = this.$route.query.tb

			if (this.$route.query.st != undefined && query.st == undefined)
				query.st = this.$route.query.st

			if (this.$route.query.dr != undefined && query.dr == undefined)
				query.dr = this.$route.query.dr

			this.$router.replace({
				name : 'calendar-schedule',
				params: {
					calendarUid: this.calendarUid
				},
				query: query,
			}).catch(() => {})
		}
	},
};
</script>

<style>
.carousel-slide{
	height: 100%;
}
.carousel-slides{
	height: 100%;
	/* overflow-y: hidden; */
}
</style>

<style scoped>

.calendar-schedule-page{
	height: 100%;
	overflow: hidden;
	display: flex;
	flex-direction: column;
}

.calendar-schedule-page > *:first-child {
	flex: 0 0 auto;
}

.calendar-schedule-page > *:not(:first-child) {
  	flex-grow: 1;
}

#schedule-calendar{
	box-shadow: none;
}

#schedule-table{
	height: 100%;
}
.header-title{
	font-size: 20px;
	font-weight: 600;
	position: fixed;
	z-index: 3;
	padding: 10px;
	width: calc(100% - 54px);
	height: 54px;
	display: flex;
	align-items: center;
}

.carousel-list{
	background: white;
	width: 100%;
}

</style>