<template>
	<div class="selected-border" v-bind:style="{
		left: left == null ? null : left + 'px',
		width: width == null ? null : width + 'px',
		display: width == null || left == null ? 'none' : null,
	}"></div>
</template>

<script>
import { isEqualDate, nextDate } from '../../../scripts/date';

export default {
	name: 'WeekSelectedDays',
	props: {
		selectedDate: {
			type: Date,
			required: true,
		},
		week: {
			type: Array,
			required: true,
		},
		daysCount: {
			type: Number,
			default: 1,
		},
		itemsContainer: {
			type: HTMLElement,
		},
		collapseStep: {
			type: Number,
		}
	},
	data() {
		return {
			itemsContainerUpdate: 0,
			width: null,
			left: null,
		}
	},

	computed: {
		weekItem(){
			this.itemsContainerUpdate
			return document.querySelector('.schedule-date')
		},
	},

	watch: {
		selectedDate(){
			this.width = this.calcWidth()
			this.left = this.calcLeft()
		},

		itemsContainer(){
			this.width = this.calcWidth()
			this.left = this.calcLeft()
		},

		selectedDate(){
			this.width = this.calcWidth()
			this.left = this.calcLeft()
		},
		weekItem(){
			this.width = this.calcWidth()
			this.left = this.calcLeft()
		},
		daysCount(){
			this.width = this.calcWidth()
			this.left = this.calcLeft()
		},
	},

	mounted() {
		this.width = this.calcWidth()
		this.left = this.calcLeft()
	},

	methods:{

		getItemWidth(){
			if (!this.itemsContainer)
				return null

			const scheduleDates = Array.from(this.itemsContainer.querySelectorAll('.schedule-date'))
			if (scheduleDates.length == 0)
				return null
			const visibleScheduleDate = scheduleDates.find(elem => elem.style.display != 'none')
			if (!visibleScheduleDate)
				return null
			return visibleScheduleDate.getBoundingClientRect().width
		},

		calcLeft(){
			if (!this.itemsContainer)
				return null

			const difference = this.daysCount == 1 ? 
				0 : this.daysCount
			const selectedDateEnd =  nextDate(this.selectedDate, difference)
			const selectedDateIndex = this.week.findIndex((date) => isEqualDate(date, this.selectedDate))
			const selectedDateEndIndex = this.week.findIndex((date) => {
				return isEqualDate(date, selectedDateEnd)
			})
			if (selectedDateIndex == -1 && selectedDateEndIndex == -1)
				return null

			const itemWidth = this.getItemWidth()
			if (!itemWidth)
				return null
			const itemGap = parseInt(window.getComputedStyle(this.itemsContainer).gap)

			if (selectedDateIndex == 0)
				return (2)

			return itemWidth * selectedDateIndex + itemGap * (selectedDateIndex) + 2	
		},
		calcWidth(){
			if (!this.itemsContainer)
				return null

			const difference = this.daysCount == 1 ? 
				0 : this.daysCount
			const selectedDateEnd = nextDate(this.selectedDate, difference)
			const selectedDateEndIndex = this.week.findIndex((date) => {
				return isEqualDate(date, selectedDateEnd)
			})
			const selectedDateIndex = this.week.findIndex((date) => isEqualDate(date, this.selectedDate))
			const itemWidth = this.getItemWidth()
			if (!itemWidth)
				return null
			const itemGap = parseInt(window.getComputedStyle(this.itemsContainer).gap)
			if (selectedDateEndIndex != -1 && selectedDateIndex == -1 && this.daysCount != 1)
			{
				return itemWidth * (selectedDateEndIndex + 1) + itemGap * (selectedDateEndIndex)
			}
			else
			{
				return itemWidth * this.daysCount + itemGap * (this.daysCount - 1)
			}

		}
	}
};
</script>

<style scoped>
.selected-border{
	position: absolute;
	top: 2px;
	height: calc(100% - 4px);
	border: 1px solid  #0075ff;
	box-shadow: 0 0 0 1.5px #adcdf3;
	border-radius: 4px;

	transition: all .3s cubic-bezier(0.175, 0.885, 0.32, 1.275);
}

</style>