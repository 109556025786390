<template>
	<div class="schedule-week">
		<div class="week-number">
			<div class="value">{{ getWeekNumber(week[0]) }}</div>
		</div>
		<div class="week-content" ref="week-content">

			<DateItem 
				v-for="date in week" 
				:key="`date-${_uid}-${date.toLocaleDateString()}`"
				:date="date"
				:eventSlots="getDateSlots(date)"
				:isCurrentMonth="checkCurrentMonth(date)"
				:isSelected="isSelected(date)"
				:collapseStep="collapseStep"
				:selector="selectHandler"
			/>

			
			<WeekSelectedDays v-if="!disableSelection"
				:collapseStep="collapseStep"
				:selectedDate="selectedDate"
				:week="week"
				:daysCount="daysCount"
				:itemsContainer="itemsContainer"
			/>

		</div>
	</div>
</template>

<script>
import { isEqualDate, smartDateSelect, weekStart } from '../../../scripts/date';
import SlotCollection from '../../../scripts/slotCollection';
import DateItem from './DateItem.vue';
import WeekSelectedDays from './WeekSelectedDays.vue';

export default {
	name: 'Week',
	components: { DateItem, WeekSelectedDays },
	model: {
		prop: 'selectedDate',
		event: 'update:selectedDate'
	},
	props: {
		week: {
			type: Array,
			required: true,
		},
		slotCollection: {
			type: SlotCollection,
			required: true,
		},
		selectedDate: {
			type: Date,
			required: true,
		},
		checkCurrentMonth: {
			type: Function,
			default: (date) => {return true},
		},
		collapseStep: {
			type: Number,
			default: 1,
		},
		disableSelection: {
			type: Boolean,
			default: false
		},
		daysCount: {
			type: Number
		},
	},

	data(){
		return {
			itemsContainer : null,
		}
	},

	mounted(){
		this.itemsContainer = this.$refs["week-content"]
	},

	methods: {
		getDateSlots(date){
			return this.slotCollection.getByDate(date, {
				sortByTime: true,
			})
		},

		selectHandler(date){
			if (this.disableSelection)
				return
		
			const newDate = smartDateSelect(date, this.daysCount)

			this.$emit('update:selectedDate', newDate)
		},

		weekIncludes(date){
			return !!this.week.find((d) => isEqualDate(d, date))
		},
		
		isSelected(date){
			if (this.disableSelection)
				return false
			return isEqualDate(date, this.selectedDate)
		},

		getWeekNumber(date) {
			const yearStart = new Date(date.getFullYear(), 0, 1)
			const weekNumber = Math.ceil(((date - yearStart) / 86400000 + yearStart.getDay() + 1) / 7)
			return weekNumber
		},
	},
};
</script>

<style  scoped>
.schedule-week{
	display: flex;
	flex-direction: row;
	flex: 1;
	min-height: 58px;
	gap: 2px;
	height: 100%;
	padding-right: 16px;
}

.week-number {
	border-right: 1.5px solid #eaeaea;
	padding-top: 2px;
	padding-right: 2px;
	text-align: center;
	color: rgb(170, 170, 170);
	font-size: 10px;
	font-weight: 500;
	width: 30px;
	height: 100%;
}

.week-content{
	display: flex;
	touch-action: none;
	position: relative;
	flex-direction: row;
	gap: 4px;
	width: 100%;
	padding-bottom: 2px;
	overflow-x: hidden;
	padding: 2px;
	box-sizing: border-box;
	overflow: hidden;
}
</style>