function getStartOfWeek(date) {
	const dayOfWeek = date.getDay();
	const startDate = new Date(date);
	if (dayOfWeek == 0)
		startDate.setDate(date.getDate() - 6);
	else
		startDate.setDate(date.getDate() - dayOfWeek + 1);
	return startDate;
}

function makeWeekByDate(date) {
	const start = getStartOfWeek(date);
	const end = new Date(start);
	end.setDate(end.getDate() + 6)
	const dates = [];

	while (start <= end) {
		dates.push(new Date(start));
		start.setDate(start.getDate() + 1);
	}

	return dates;
}

function makeMonthByDate(date) {
	const start = new Date(date.getFullYear(), date.getMonth(), 1);
	const end = new Date(date.getFullYear(), date.getMonth() + 1, 0);
	let weeks = {};
	let counter = 0;
	while (start <= end) {
		const week = makeWeekByDate(start);
		weeks[counter++] = week;
		start.setDate(start.getDate() + 7);
	}

	return weeks;
}



export {
	getStartOfWeek,
	makeWeekByDate,
	makeMonthByDate,
}