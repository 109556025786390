import CMS from "../service/cms/service"

import { convertTZ, dateRange, isEqualDate, UTCString } from "./date"
import Slot from "./slot"

/**
 * Sorting fucn for the slots
 * @param {Slot} slotA - first slot
 * @param {Slot} slotB - second slot
 * @returns time difference between slots start date
 */
function sortByTime(slotA, slotB) {
	return slotA.period.start.time - slotB.period.start.time
}

function getCollectionIndex(){
	let index = 0
	return () => {
		index += 1
		return index
	}
}

const nextCollectionIndex = getCollectionIndex()


/**
 * Provide the collection united by any data (as an example date)
 */
class SlotCollection {
	/**
	 * @param {Array} slots - array of slots
	 * @param {String} calendarUid - calendar uid
	 * @param {SlotCollection} [origin=undefined] - slot collection origin
	 */

	relatedUsers = {} // users that are attenders or hosts in this slot collection
	childCollections = {}
	loadingParams = null
	from = null
	to = null
	
	constructor(calendarUid, slots, origin=undefined) {
		this.index = nextCollectionIndex()
		this.slots = slots
		this.questionSlots = []
		this.calendarUid = calendarUid
		this.origin = origin
		this.isLoaded = false
	}

	/**
	 * Return the amount of the slots
	 * @returns 
	 */
	length() {
		return this.slots.length
	}

	includes(slotId) {
		return this.slots.find(slot => {
			return slot.index == slotId
		}) != undefined
	}

	/**
	 * Return the amount of the question slots
	 * @returns 
	 */
	questionLength(){
		return this.questionSlots.length
	}

	/**
	 * Return full list of slots (question slots is included)
	 * @param {Object} opt - options
	 * @returns 
	 */
	fullSlotList(opt={
		sortByTime: false,
	}){
		let slots = [...this.slots]
		slots.push(...this.questionSlots)
		if (opt.sortByTime)
			slots = slots.sort(sortByTime)
		return slots
	}

	/**
	 * Make the new SlotCollection united by date.
	 * Record the new collection into the childCollections field.
	 * @param {Date} date - union parameter
	 * @returns New SlotCollection united by date
	 */
	collectionByDate(date) {
		let slots = this.getByDate(date)
		let collection = new SlotCollection(this.calendarUid, slots, this)
		collection.isLoaded = this.isLoaded
		collection.from = date
		collection.to = date
		collection.questionSlots = this.questionSlots.filter(slot => {
			return isEqualDate(slot.period.start.date, date)
		})
		collection.relatedUsers = this.relatedUsers
		this.$addChildCollection(collection)
		return collection
	}

	/**
	 * Make the new child SlotCollection united by range with start and end date.
	 * Record the new collection into the childCollections field.
	 * @param {Date} startDate - union parameter
	 * @param {Date} endDate - union parameter
	 * @returns New SlotCollection united by range with start and end date
	 */
	collectionByRange(startDate, endDate) {
		let slots = []

		const start = new Date(startDate)
		start.setHours(0, 0, 0, 0)
		const end = new Date(endDate)
		end.setHours(0, 0, 0, 0)

		let iter = new Date(start)
		while (iter <= end) {
			slots.push(...this.getByDate(iter))
			iter.setDate(iter.getDate() + 1)
		}

		let collection = new SlotCollection(this.calendarUid, slots, this)
		collection.isLoaded = this.isLoaded
		collection.from = start
		collection.to = end

		iter = new Date(start)

		while (iter <= end) {
			collection.questionSlots.push(...this.questionSlots.filter(slot => {
				return isEqualDate(slot.period.start.date, iter)
			}))
			iter.setDate(iter.getDate() + 1)
		}
		collection.relatedUsers = this.relatedUsers
		this.$addChildCollection(collection)
		return collection
	}

	/**
	 * Remove child collection and their child collections (recursively)
	 * @param {SlotCollection} collection 
	 * @returns 
	 */
	removeCollection(collection){
		if (!collection || !this.childCollections[collection.index])
			return
		Object.values(collection.childCollections).forEach(childCollection => {
			collection.removeCollection(childCollection)
		})
		this.$removeChildCollection(collection)
	}

	neighborByStart(slotId, {hour, minute}) {
		let neighbor = this.slots
			.filter(slot => slot.index != slotId)
			.find(neighbor => {
				return neighbor.period.start.date.getHours() == hour &&
					neighbor.period.start.date.getMinutes() == minute &&
					neighbor.index != slotId
			})
		
		if (!neighbor)
			return undefined

		return neighbor
	}

	neighborByEnd(slotId, {hour, minute}) {
		let neighbor = this.slots
			.filter(slot => slot.index != slotId)
			.find(neighbor => {
				return neighbor.period.end.date.getHours() == hour &&
						neighbor.period.end.date.getMinutes() == minute &&
						neighbor.index != slotId
			})

		if (!neighbor)
			return undefined

		return neighbor
	}

	/**
	 * Update the slot list of a collection
	 * @param {Date} from - from date
	 * @param {Date} to - to date 
	 * @param {Object} browserTZ - timezone of the client browser
	 * @param {Object} calendarTZ - timezone of the current calendar 
	 * @param {boolean} [cleanOld=false] - clean old slots from collection
	 */
	updateSlots(from, to, browserTZ, calendarTZ, cleanOld=false) {
		this.loadingParams = {from, to, browserTZ, calendarTZ}
		return CMS.slots.getAll(this.calendarUid, from, to)
		.then(data => {
			if (data && data.err)
				throw data.err

			const slots = []

			if (data.slots) {
				data.slots.forEach(slotInfo => {
					if (this.includes(slotInfo.id) && !cleanOld)
						return


					const date = new Date(slotInfo.startAt)
					const dateInCalendarTZ = convertTZ(date, browserTZ, calendarTZ)
					const dur = slotInfo.dur

					delete slotInfo.startAt
					delete slotInfo.dur
					
					slots.push(new Slot(dateInCalendarTZ, dur, slotInfo))
				})
			}

			if (cleanOld) {
				this.slots = slots
			} else {
				this.slots.push(...slots)
			}

			if (data.users) {
				this.relatedUsers = data.users
			}

			this.updateChildSlots(cleanOld)
				
			return slots
		})
		.catch(err => {
			console.log(err)
		})
		.finally(() => {
			this.isLoading = false
		})
	}

	updateChildSlots(cleanOld=false){
		
		Object.values(this.childCollections).forEach(collection => {
			collection.relatedUsers = this.relatedUsers
			const isOneDay = isEqualDate(collection.from, collection.to)

			const updatedTo = new Date(collection.to)
			updatedTo.setDate(updatedTo.getDate() + 1)
			updatedTo.setMinutes(updatedTo.getMinutes() - 1)
			
			const slots = isOneDay ?
				this.getByDate(collection.from) :
				this.getByRange(collection.from, updatedTo)
			
			if (cleanOld) {
				collection.slots = slots
			} else {
				const filteredSlots = slots.filter(slot => {
					return !collection.slots.includes(slot)
				})
				collection.slots.push(...filteredSlots)
			}
			collection.updateChildSlots(cleanOld)
		})
	}

	/**
	 * Make filter of the slots by date and other support options
	 * @param {Date} date - date
	 * @param {Object} opt - other support options
	 * @returns array of slots by date
	 */
	getByDate(date, opt={
		withQuestionSlots: false,
		sortByTime: false,
	}) {
		
		let slots = []

		slots = this.slots.filter(slot => {
			return isEqualDate(slot.period.start.date, date)
		})

		if (opt.withQuestionSlots) {
			slots.push(this.questionSlots.filter(slot => {
				return isEqualDate(slot.period.start.date, date)
			}))
		}

		if (opt.sortByTime) {
			slots = slots.sort(sortByTime)
		}

		return slots
	}

	getById(slotId) {
		return this.slots.find(slot => slot.index == slotId)
	}

	loadingParamsIsEqual(from, to, browserTZ, calendarTZ){
		if (this.loadingParams == null)
			return false

		return isEqualDate(this.loadingParams.from, from)
			&& isEqualDate(this.loadingParams.to, to)
			&& this.loadingParams.browserTZ.id == browserTZ.id
			&& this.loadingParams.calendarTZ.id == calendarTZ.id
	}

	/**
	 * Make filter of the slots by range and other support options
	 * @param {Date} from from date
	 * @param {Date} to to date
	 * @param {Object} opt - other support options
	 * @returns array of slots by range
	 */
	getByRange(from, to, opt={
		withQuestionSlots: false,
		sortByTime: false,
	}) {
				
		let slots = []

		slots = this.slots.filter(slot => {
			return slot.period.start.date >= from &&
					slot.period.start.date <= to
		})

		if (opt.withQuestionSlots) {
			slots.push(this.questionSlots.filter(slot => {
				return slot.period.start.date >= from &&
						slot.period.start.date <= to
			}))
		}

		if (opt.sortByTime) {
			slots = slots.sort(sortByTime)
		}

		return slots
	}

	getQuestionsByDate(date, opt={
		sortByTime: false,
	}) {

		let result = this.questionSlots
		.filter(slot => isEqualDate(date, slot.period.start.date))

		if (opt.sortByTime) {
			result = result.sort(sortByTime)
		}

		return result
	}

	updateSlotTime(slot, time, browserTZ, calendarTZ) {
		let slotId = slot.index
		let dateIsChanged = !isEqualDate(slot.period.start.date, time)
		let convertedTime = convertTZ(time, calendarTZ, browserTZ)
		return CMS.slots.patch(this.calendarUid, slotId, {startAt: UTCString(convertedTime)})
		.then(slotData => {
			if (slotData.err){
				throw slotData.err
			}
			
			if (dateIsChanged) {
				this.replaceSlot(slot, time)
			} else {
				slot.period.update(time)
			}

			return time
		}).catch(err => {
			console.log(err)
			return err
		})
	}

	submitSlot(slot, browserTZ, calendarTZ, isProCalendar) {

		let submitData = slot.toSubmitData(isProCalendar)

		submitData.startAt = convertTZ(submitData.startAt, calendarTZ, browserTZ)
		submitData.startAt = UTCString(submitData.startAt)
		submitData.timezoneId = calendarTZ.id
		if (slot.title)
			submitData.title = slot.title
		// let hosts = getters.calendarHosts
		// let ratings = getters.calendarRatings
		// let priceLevels = getters.calendarPriceLevels
	
		// if (hosts && hosts.length == 1)
		// 	slotData["hostId"] = hosts[0].id
		// if (ratings && ratings.length == 1)
		// 	slotData["ratingId"] = ratings[0].id
		// if (priceLevels && priceLevels.length == 1)
		// 	slotData["levelId"] = priceLevels[0].id

		return CMS.slots.upload(this.calendarUid, submitData)
		.then((newSlots) => {          
			if (newSlots.err){
				throw newSlots.err
			}   
	
			if (!newSlots || newSlots.length == 0) 
				return
			let slots = newSlots.map(slotInfo => {
				let date = new Date(slotInfo.startAt)
				date = convertTZ(date, browserTZ, calendarTZ)
				return new Slot(date, slotInfo.dur, slotInfo)
			}) 
	
			this.addSlots(slots)

			return slots
		}).finally(() => {
			if (slot.settings.isQuestionSlot) {
				this.removeQuestionSlot(slot)
			}
		})
	}

	addQuestionSlot(questionSlot){
		this.addQuestionSlotToOrigin(questionSlot)
		this.addQuestionSlotToChild(questionSlot)
	}

	addQuestionSlotToOrigin(questionSlot){
		let iter = this
		
		while (iter.origin) {
			iter = iter.origin
			if (!iter)
				break
			iter.questionSlots.push(questionSlot)
		}
	}

	addQuestionSlotToChild(questionSlot){
		if (!questionSlot)
			return
		const questionSlotDate = questionSlot.period.start.date
		this.questionSlots.push(questionSlot)
		Object.values(this.childCollections)
		.forEach(collection => {
			const collectionDateRange = dateRange(collection.from, collection.to)
			const isContainSlotDate = !!collectionDateRange
				.find(date => isEqualDate(date, questionSlotDate))
			if (!isContainSlotDate)
				return
			collection.addQuestionSlotToChild(questionSlot)
		})
	}

	removeQuestionSlot(questionSlot){
		this.removeQuestionSlotFromOrigin(questionSlot)
		this.removeQuestionSlotFromChild(questionSlot)
	}
	
	removeQuestionSlotFromOrigin(questionSlot){
		let iter = this
		
		while (iter.origin) {
			iter = iter.origin
			if (!iter)
				break
			iter.questionSlots = iter.questionSlots.filter(slot => {
				return questionSlot !== slot
			})
		}
	}
	removeQuestionSlotFromChild(questionSlot){
		if (!questionSlot)
			return
		const questionSlotDate = questionSlot.period.start.date
		this.questionSlots = this.questionSlots.filter(slot => {
			return questionSlot !== slot
		})
		Object.values(this.childCollections)
		.forEach(collection => {
			const collectionDateRange = dateRange(collection.from, collection.to)
			const isContainSlotDate = !!collectionDateRange
				.find(date => isEqualDate(date, questionSlotDate))
			if (!isContainSlotDate)
				return
			collection.removeQuestionSlotFromChild(questionSlot)
		})
	}

	addSlots(slots){
		if (!slots || (slots instanceof Array && slots.length == 0))
			return

		const newSlots = []
		if (slots instanceof Array) {
			newSlots.push(...slots)
		} else if (slots instanceof Slot) {
			newSlots.push(slots)
		}

		this.addSlotsToOrigin(newSlots)
		this.addSlotsToChild(newSlots)
	}

	addSlotsToOrigin(newSlots){
		let iter = this
		
		while (iter.origin) {
			iter = iter.origin
			if (!iter)
				break
			iter.slots.push(...newSlots)
		}
	}

	addSlotsToChild(newSlots){
		if (!newSlots || newSlots.length == 0)
			return
		this.slots.push(...newSlots)
		Object.values(this.childCollections)
		.forEach(collection => {
			const collectionDateRange = dateRange(collection.from, collection.to)
			const slots = newSlots.filter(slot => {
				const isContainSlotDate = collectionDateRange
					.find(date => isEqualDate(date, slot.period.start.date))
				return !!isContainSlotDate
			})
			if (!slots || slots.length == 0)
				return
			collection.addSlotsToChild(slots)
		})
	}

	removeSlotsFromChild(removedSlots){
		if (!removedSlots || removedSlots.length == 0)
			return
		
		this.slots = this.slots.filter(s => {
			return removedSlots.indexOf(s) == -1
		})
		Object.values(this.childCollections)
		.forEach(collection => {
			const collectionDateRange = dateRange(collection.from, collection.to)
			const slots = removedSlots.filter(slot => {
				const isContainSlotDate = collectionDateRange
					.find(date => isEqualDate(date, slot.period.start.date))
				return !!isContainSlotDate
			})
			if (!slots || slots.length == 0)
				return
			collection.removeSlotsFromChild(slots)
		})
	}

	replaceSlot(slot, newDate) {
		if (!slot || !this.slots.includes(slot))
			return false 

		let head = this
		while (head.origin) {
			head = head.origin
		}
		
		head.removeSlotsFromChild([slot])
		slot.period.update(newDate)
		head.addSlotsToChild([slot])

		return true
	}

	$deleteSlot(delSlot) {
		this.slots = this.slots.filter(slot => {
			return slot !== delSlot
		})
		if (this.origin)
			this.origin.$deleteSlot(delSlot)
	}

	removeSlot(slot){
		return CMS.slots.delete(this.calendarUid, slot.index)
		.then(data => {
			if (data.err)
				throw data.err
			this.$deleteSlot(slot)
		})
	}

	$addChildCollection(collection){
		this.childCollections[collection.index] = collection
	}

	$removeChildCollection(collection){
		if (!collection || !this.childCollections[collection.index])
			return 
		delete this.childCollections[collection.index]
	}
}

export default SlotCollection