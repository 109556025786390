<template>
	<div class="card">

		<div class="card-header">
			<div class="card-header-icon">
				<b-icon icon="school" size="is-small"></b-icon>
			</div>
			<div class="card-header-title">
				<div class="attender-name">
					{{ attender.fullName }}
				</div>
				<div class="attender-checked">
					<b-checkbox v-model="attender.isConfirm"></b-checkbox>
				</div>
				<div class="attender-price">
					<span v-if="!isOpenEditPrice && attender.price != null" 
					@click="() => isOpenEditPrice = true">
						{{ `${attender.price}` }}
					</span>
					<b-input v-if="isOpenEditPrice" size="is-small" v-model="attender.price"></b-input>
				</div>
			</div>
			<div class="card-header-icon" @click="openHandler">
				<b-icon :icon="openedIndex == attender.userId ? 
					'chevron-up' : 'chevron-down'">
				</b-icon>
			</div>
		</div>

		<b-collapse :open="openedIndex == attender.userId" animation="slide">
			<div class="card-content">
				<textarea class="textarea is-small" v-model="attender.comment" placeholder="Comment" rows="3">
				</textarea>
			</div>
		</b-collapse>
	</div>
</template>

<script>
import { AttenderConfirmation } from '../../../scripts/attender';

export default {
	name: 'AttenderListItem',

	emits: [
		'on-open'
	],

	props: {
		attender: {
			type: AttenderConfirmation,
			required: true,
		},
		openedIndex: {
			type: Number,
			required: true,
		}
	},

	data() {
		return {
			isOpenEditPrice: false,
		};
	},

	watch: {
		'attender.isConfirm': {
			handler(value){
				if (this.attender.isConfirm && this.attender.price == null) {
					this.isOpenEditPrice = true
				}
			},
			deep: true,
		}
	},

	mounted() {
		
	},

	methods: {
		openHandler(){
			if (this.openedIndex == this.attender.userId) {
				this.$emit('on-open', 0)
			} else {
				this.$emit('on-open', this.attender.userId)
			}
		}
	},
};
</script>

<style  scoped>

.card-header{
	height: 36px;
	font-size: 14px;
}

.card-content{
	padding: 10px;
}

.card-header-title{
	padding: 0;
	display: flex;
}

.attender-checked{
	padding: 0;
	display: flex;
	align-items: center;

}
.attender-name{
	flex: 1;
}

.card-header-icon{
	padding: 0 12px;
}

.content div:not(:last-child){
	margin-bottom: 0;
	padding-top: 0;
	padding-bottom: 0;
	font-weight: normal;
}

.attender-price{
	width: 40px;
	text-decoration: underline;
	color: #0075ff;
}

.textarea {
  resize: none !important;
}
</style>