<template>
	<div class="schedule-calendar-collapse" ref="collapse-calendar">
		<slot></slot>
	</div>
</template>

<script>
import { bus } from '../EventBus';

export default {
	name: 'CalendarCollapseBehavior',

	emits: [
		'on-collapse-start',
		'on-collapse-move',
		'on-collapse-end',
	],

	model : {
		prop: 'collapse',
		event: 'update:collapse',
	},

	props: {
		collapse:{
			type: Number,
			required: true,
		},
		setActivityFunc: {
			type: Function,
			required: true,
		},
		activity: {
			type: String,
		},
		collapseMods: {
			type: Object,
			required: true,
		}
	},

	data() {
		return {
			lastPointerY: 0,
			lastPointerX: 0,
			initialHeight: 0,
			lastCollapseDate: null,
		};
	},

	watch: {
		collapse(newValue) {
			if (newValue != 1 && newValue != 2)
				newValue = 0
			const newMod = this.collapseMods[newValue]
			if (!newMod)
				return
			this.setHeightWithTransition(`${newMod.height}px`)
		},
	},

	mounted() {


		let collapse = this.$el
		collapse.style.height = `${this.collapseMods[this.collapse].height}px`
		collapse.addEventListener('pointerdown', this.pointerDownCollapse)
		collapse.addEventListener('pointermove', this.pointerMoveCollapse)
		collapse.addEventListener('pointerup', this.pointerUpCollapse)
	},

	beforeDestroy() {
		bus.$off('calendar-collapse-behavior')
		let collapse = this.$el
		collapse.removeEventListener('pointerdown', this.pointerDownCollapse)
		collapse.removeEventListener('pointermove', this.pointerMoveCollapse)
		collapse.removeEventListener('pointerup', this.pointerUpCollapse)
	},

	methods: {

		calculateDeltaY(ev){
			let deltaY = ev.clientY - this.lastPointerY

			if (Math.abs(deltaY) < 40)
				return 0

			deltaY > 0 ?
				deltaY -= 40 : deltaY += 40

			if (this.initialHeight + deltaY > this.collapseMods[2].height)
				return 0

			if (this.initialHeight + deltaY < this.collapseMods[0].height)
				return 0
			
			return deltaY
		},

		checkActivity(){
			if (this.activity && this.activity != "collapse") 
				return false
			if (this.activity == "collapse")
				return true
			this.setActivityFunc("collapse")
			return true
		},

		pointerDownCollapse(ev){
			this.lastPointerY = ev.clientY
			this.lastPointerX = ev.clientX

			let collapse = this.$el
			collapse.setPointerCapture(ev.pointerId)
			this.initialHeight = collapse.getBoundingClientRect().height
			this.lastCollapseDate = new Date()
			this.$emit('on-collapse-start')
		},

		pointerMoveCollapse(ev){

			let collapse = this.$el
			let deltaY = this.calculateDeltaY(ev)
			if (deltaY == 0)
				return

			if (!this.checkActivity())
				return

			collapse.style.height = this.initialHeight + deltaY + 'px'

			this.$emit('on-collapse-move', deltaY)
		},
		pointerUpCollapse(ev) {
			
			let deltaY = this.calculateDeltaY(ev)

			this.setActivityFunc(null)

			let now = new Date()
			if (Math.abs(deltaY) > 100 && now - this.lastCollapseDate < 300) {
				this.fastCollapseHandler(deltaY)
			} else {
				this.longCollapseHandler()
			}
			this.$emit('on-collapse-end')
		},

		fastCollapseHandler(deltaY){
			let futureCollapse = null
			if (this.collapse == 0 && deltaY > 0 || this.collapse == 2 && deltaY < 0)
				futureCollapse = 1
			else if (this.collapse == 1 && deltaY < 0)
				futureCollapse = 0
			else if (this.collapse == 1 && deltaY > 0)
				futureCollapse = 2

			this.updateCollapse(futureCollapse)
		},
		
		longCollapseHandler(){
			let collapse = this.$el
			let collapseHeight = collapse.getBoundingClientRect().height
			let closestMods = Object.keys(this.collapseMods).sort((a,b) => {
				const difA = Math.abs(this.collapseMods[a].height - collapseHeight) 				
				const difB = Math.abs(this.collapseMods[b].height - collapseHeight)
				return difA - difB 				
			})

			let futureCollapse = Number(closestMods[0])
			this.updateCollapse(futureCollapse)
		},

		updateCollapse(futureCollapse){
			if (futureCollapse == null || futureCollapse == this.collapse){
				this.setHeightWithTransition(`${this.initialHeight}px`)
			} else if (futureCollapse == 2) {
				this.setHeightWithTransition('100dvh')
				this.$emit('update:collapse', futureCollapse)
			} else {
				this.setHeightWithTransition(`${this.collapseMods[futureCollapse].height}px`)
				this.$emit('update:collapse', futureCollapse)
			}
		},

		setHeightWithTransition(value, duration=0.3){
			const collapse = this.$el
			collapse.style.transition = `height ${duration}s ease-in-out`
			collapse.style.height = value
			setTimeout(() => {
				collapse.style.transition = null
			}, duration*1000)
		},
	},
};
</script>

<style scoped>
.schedule-calendar-collapse{
	touch-action: none;
	flex: 1;
	width: 100%;
	min-height: 62px;
	max-height: calc(100dvh - 32px - 10px - 27px - 30px - 50px);
	overflow: hidden;
	border-bottom: 1.5px solid rgb(232, 232, 232);
}
</style>