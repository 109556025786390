<template>
	<transition name="tools">
		<div class="base-slot-tools" v-if="isOpen"
		:id="`base-slot-tools-${ eventSlot.index }`"
		v-click-outside="closeTools"
		v-bind:style="styles">
			<div class="slot-tool"
			v-for="tool in tools"
			:key="`slot-${eventSlot.index}-tool-${tool.icon}}`"
			@click="toolActionHandler(tool)"
			v-bind:class="{
				'is-danger': tool.type == 'is-danger',
				'is-disabled': tool.type == 'is-disabled',
			}">
				<div class="tool-icon">
					<b-icon 
						:icon="tool.icon"
						custom-size="mdi-18px"
						:disabled="tool.disabled"
						:type="tool.type ? tool.type : null">
					</b-icon>
				</div>
				<div class="tool-title">
					{{ tool.name }}
				</div>
			</div>
		</div>
	</transition>
</template>

<script >
import { globalBus } from '../../GlobalEventBus';
import { setLastPressedSlot } from '../../scripts/lastPressedSlot';
import Slot from '../../scripts/slot';
import SlotCollection from '../../scripts/slotCollection';

const TOOLS_WINDOW_HEIGHT = 114

class Tool {
	constructor(icon, name, action, type=null){
		this.icon = icon
		this.name = name
		this.action = action
		this.type = type
	}
}


export default {
	name: 'BaseSlotTools',
	model: {
		prop: 'isOpen',
		event: 'update:isOpen',	
	},

	props: {
		isOpen: {
			type: Boolean,
			required: true,
		},
		eventSlot: {
			type: Slot,
			required: true,
		},
		slotCollection: {
			type: SlotCollection,
			required: true,
		},
		cellHeight: {
			type: Number,
			default: 54,
		},
		positionX: {
			type: String,
			default: "right",
			validation: (value) => {
				return value == "right" ||
						value == "left"
			}
		},
		positionY: {
			type: String,
			default: "top",
			validation: (value) => {
				return value == "top" ||
						value == "bottom"
			}
		},
		viewType: {
			type: String,
			default: 'full',
			validation(value) {
				return ['full', 'middle'].includes(value)
			}
		}
	},
	directives: {
        "click-outside": {
            bind: function (el, binding, vnode) {

				const slotIdParts = el.id.split('-')
				const slotIndex = slotIdParts[slotIdParts.length - 1]
				
				el.clickOutsideEvent = function (event) {
					const selector = document.getElementById('tools-container-' + slotIndex)

					// If it is a button that open this popup
					if (!selector || selector == event.target || selector.contains(event.target) || event.target.nodeName == 'I')
						return

					if (!(el == event.target || el.contains(event.target))) {
                        vnode.context[binding.expression](event);
                    }
                };
                document.body.addEventListener("pointerdown", el.clickOutsideEvent);
            },
            unbind: function (el) {
                document.body.removeEventListener(
                    "pointerdown",
                    el.clickOutsideEvent
                );
            },
        },
    },
	computed: {
		styles(){
			let styles = {}

			if (this.positionY == 'top') {
				styles.bottom = this.slotHeight() + 14 + 'px'
			} else if (this.positionY == 'bottom') {
				styles.top = this.cellHeight + 14 + 'px'
			}

			if (this.positionX == 'right') {
				styles.right = '0'
			} else if (this.positionX == 'left') {
				styles.left = '0'
			}

			return styles
		},
		tools(){
			if (this.viewType == 'short')
				return null
			
			let tools = []

			let now = new Date()
			if (now > this.eventSlot.period.start.date)
				tools.push(new Tool('file-document-outline', 'Confirmation', this.openConfirmation))

			tools.push(new Tool('link', 'Copy link', this.copyLink))
			tools.push(new Tool('file-plus-outline', 'Save as template', this.saveAsTemplate))
			tools.push(new Tool('delete', 'Delete', this.removeSlot, 'is-danger'))
			

			if (this.viewType == 'middle')
				tools.unshift(new Tool('pencil', 'Edit', this.openSettings))

			return tools
		}
	},

	methods: {
		closeTools(){
			this.$emit('update:isOpen', false)
		},
		toolActionHandler(tool) {
			if (tool.type == 'is-disabled')
				return
			tool.action()
		},
		openConfirmation(){
			globalBus.$emit('attendee-confirmation', 'show', this.eventSlot)
		},
		copyLink(){
			if (navigator.clipboard) {
				const origin = window.location.origin
				const calendarUid = this.$route.params.calendarUid
				const slotLink = `${origin}/${calendarUid}/slots/${this.eventSlot.index}`

				navigator.clipboard.writeText(slotLink).then(_ => {
					this.$buefy.toast.open({
						duration: 5000,
						type: 'is-info',
						message: `📄 Link successfully copied`,
						position: 'is-bottom',
					})
					this.closeTools()
				})
			}
		},
		saveAsTemplate(){
			this.closeTools()
		},
		removeSlot(){
			if (this.slotIsPast)
				return

			this.slotCollection.removeSlot(this.eventSlot)
			.then(data => {
				if (data && data.err)
					return
				this.closeTools()
			})
		},

		openSettings(){
			if (this.eventSlot.settings.isQuestionSlot)
				return

			setLastPressedSlot(this.eventSlot.index)

			this.$router.push({
				name: "slot-edit", 
				params: {
                	calendarUid: this.$route.params.calendarUid,
                	slotId: this.eventSlot.index,
				}
			})
		},

		slotHeight(){
			const scaleValue = this.eventSlot.period.duration / 60
			return this.cellHeight * scaleValue	
		}
	},
}

</script>

<style scoped>
.base-slot-tools{
	position: absolute;
	width: 150px;
	background: white;
	border: 1px solid lightgrey;
	border-radius: 5px;
	z-index: 7;
}

/* .base-slot-tools.is-expanded{
	width: 100%;
} */

.slot-tool{
	display: flex;
	padding: 5px 8px;
	gap: 10px;
	align-items: center;
	font-size: 12px;
	border-radius: 5px;
}

.slot-tool:hover {
	box-shadow: 0 0 0 1.5px #adcdf3;
}

.slot-tool.is-danger{
	color: #f14668;
}

.slot-tool.is-disabled{
	color: grey;
	background: rgb(240, 240, 240);
}


.slot-tool.is-danger:hover{
	box-shadow: 0px 0 0 1.5px #ffcece;
	background: #ffeded;
}

.tools-enter-active{
	animation: fade .15s;
}

.tools-leave-active{
	animation: fade .15s reverse;
}
</style>