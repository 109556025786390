<template>
	<div class="calendar-month-bar">
		<div class="bar__current-info">
			<div class="calendar-month-name">
				{{ currentMonthName }}
			</div>
			<div class="calendar-month-button" @click="() => onSelectDate(new Date())">
				<div class="current-date__icon">
					<span class="iconify" 
					data-icon="material-symbols:calendar-today-outline-rounded"
					data-width="24" data-height="24"></span>
				</div>
				<div class="current-date__value">
					{{ (new Date()).getDate() }}
				</div>
			</div>
		</div>
		<div class="bar__select-mode">
			<b-dropdown aria-role="list" :mobile-modal="false" 
			animation="" 
			position="is-bottom-left">
				<template #trigger>
					<div class="dropdown-button" v-bind:class="{
						'is-open' : isOpen,
					}" role="button" @click="isOpen = !isOpen">
						<div>{{ selectedModeTitle }}</div>
						<div class="dropdown-button__icon">
							<span class="iconify" 
							data-icon="material-symbols:keyboard-arrow-down-rounded"
							data-width="22" data-height="22"></span>
						</div>
					</div>
				</template>


				<b-dropdown-item v-for="item in calendarModesItems"
				:key="`calendar-mode-${item.index}-${item.days}`"
				@click="selectHandler(item)" 
				aria-role="listitem" paddingless>
					<div class="mode-item"	v-bind:class="{
						selected: isSelected(item)
					}">
						{{ item.title }}
					</div>
				</b-dropdown-item>
			</b-dropdown>
		</div>
	</div>
</template>

<script>
import { enLongMonths } from '../../../scripts/months';

const calendarModes = [
	{index: 0, title: "Daily", days: 1},
	{index: 4, title: "2 Days", days: 2},
	{index: 3, title: "3 Days", days: 3},
	{index: 2, title: "5 Days", days: 5},
	{index: 1, title: "7 Days", days: 7},
]

export default {
  	name: 'MonthBar',
	model: {
		prop: 'days',
		event: 'update:days',
	},
	emits: [
		'on-select-date',
	],
	props: {
		days: {
			type: Number,
			required: true,
		},
		currentMonthIndex: {
			type: Number,
			required: true,
		},
		months: {
			type: Array,
			required: true,	
		}
	},

	computed: {
		currentMonthName(){
			let monthDate
			let now = new Date()
			monthDate = this.months[this.currentMonthIndex][0][6];

			if (now.getFullYear() == monthDate.getFullYear())
				return enLongMonths[monthDate.getMonth()]

            let monthName = enLongMonths[monthDate.getMonth()];
            let yearName = monthDate.getFullYear();	
            return `${monthName} ${yearName}`;
		},
		calendarModesItems(){
			return calendarModes
		},
		selectedModeTitle(){
			const days = this.days
			const currentMode = calendarModes.find(item => {
				return days == item.days
			}) 
			if (!currentMode)
				return ""

			return currentMode.title
		}
	},

	data() {
		return {
			isOpen: false,
		};
	},


	methods: {
		selectHandler(item){
			if (this.isSelected(item))
				return
			this.$emit('update:days', item.days)
			this.isOpen = false
		},
		isSelected(item) {
			return item.days == this.days
		},
		onSelectDate(date) {
			this.$emit('on-select-date', date)
		},
	},
};
</script>

<style>
.dropdown-menu {
	min-width: 100%;
}

a.dropdown-item{ 
	padding-right: 16px;
}

a.dropdown-item.selected{
	border: 1px solid brown;
}

.dropdown-enter-active {
	animation: open-dropdown 0.3s ease-in-out;
}

.dropdown-leave-active {
	animation: open-dropdown 0.3s ease-in-out reverse;
}

@keyframes open-dropdown {
	from {
		max-height: 0;
	}
	to {
		max-height: 100%;
	}
}

</style>

<style scoped>

.mode-item{
	padding: 6px 12px;
}

.mode-item.selected{
	border: 1px solid #0075ff;
	border-radius: 4px;
	box-shadow: 0 0 0 1.5px #adcdf3;
}

.calendar-month-bar{
	position: relative;
	margin: 6px 16px 10px 30px;

	display: flex;
	flex-direction: row;
	justify-content: space-between;
	align-items: center;
}

.bar__current-info{
	display: flex;
	flex-direction: row;
	gap: 10px;
}

.bar__select-mode{
	height: 100%;
}

.calendar-month-button{
	position: relative;
	display: flex;
	justify-content: center;
	user-select: none;
	align-items: center;
}

.current-date__icon {
	height: 22px;
}
.current-date__value {
	position: absolute;
	top: 1px;
	left: 0;
	z-index: inherit;
	font-weight: 500;
	font-size: 10px;
	width: 100%;
	display: flex;
	justify-content: center;
	margin-top: 10px;
	user-select: none;
}

.calendar-month__date{
	flex: 1;
	display: flex;
	justify-content: center;
	align-items: center;
	height: 32px;
}

.calendar-month__date.label{
	font-size: 14px;
	font-weight: 600;
	color: grey;
	margin-bottom: 0;
}

.calendar-month-name {
	font-size: 20px;
	font-weight: 500;
	text-align: center;
}

.dropdown-button{
	display: flex;
	align-items: center;
	justify-content: space-between;
	font-size: 14px;
	width: 12ch;
	padding: 6px 12px;

	border: 1px solid #eaeaea;
	border-radius: 4px;
}



.dropdown-button > .dropdown-button__icon{
	transition: transform 0.3s;
}


.dropdown-button.is-open > .dropdown-button__icon{
	transform: rotate(180deg);
}

.dropdown-button__icon{
	display: flex;
	align-items: center;
	color: grey;
}


</style>