<template>
	<div class="delete-slot">
		<transition name="garbage">
			<div class="delete-slot__bucket" 
			v-if="show" 
			v-bind:class="{
				preparation : isDeletePreparation,
			}">
				<span class="iconify" 
				data-icon="uil:trash-alt" 
				data-width="32" 
				data-height="32">
				</span>
			</div>
		</transition>
	</div>
</template>

<script>
export default {
	name: 'ScheduleTableDelete',
	props: {
		show: {
			type: Boolean,
			default: false,
		},
		isDeletePreparation: {
			type: Boolean,
			default: false,
		},
	},
};
</script>

<style scoped>
.delete-slot{
    color: #f28383;
    height: 50px;
    width: 100%;

    display: flex;
    justify-content: center;
    align-items: center;

    position: fixed;
    bottom: 0;
    z-index: 3;
    transition: width 2s, height 2s;
}

.delete-slot__bucket{
    display: flex;
    justify-content: center;
    align-items: center;

    height: 50px;
    width: 50px;

    border-radius: 50px;
}

.delete-slot__bucket.preparation{
    color: red;
    border: 1px solid red;
    -webkit-box-shadow: 0px 0px 32px 3px rgba(255, 11, 0, 0.5);
	-moz-box-shadow: 0px 0px 32px 3px rgba(255, 11, 0, 0.5);
	box-shadow: 0px 0px 32px 3px rgba(255, 11, 0, 0.5);
}

.garbage-enter-active {
	animation: garbage 0.3s;
}

.garbage-leave-active{
	animation: garbage 0.3s reverse;
}

@keyframes garbage {
	from {
		opacity: 0;
	}
	to {
		opacity: 1;
	}
}
</style>