<template>
	<div class="schedule-date"
	:id="`date-${date.toLocaleDateString()}`"
	v-bind:class="{
		'disabled': !isCurrentMonth,
		'is-title-only': !isShowEventSlots,
		'is-selected': isSelected,
	}">
		<div class="schedule-date__selector"
		@click="selector ? selector(date) : null">
		</div>

		<div class="schedule-date__title" v-bind:class="{
			'short' : titleIsShort,
			'is-today': isToday(),
			'is-holiday': isHoliday(date) || isSpecialDay(date),
		}">
			{{ date.getDate()}}
		</div>
		<ScheduleDateSlots
			:eventSlots="eventSlots"
			:isShowEventSlots="isShowEventSlots"
			:maxEventSlots="maxEventSlots"
			:collapseStep="collapseStep"
		/>
	</div>
</template>

<script>
import { getTimeString, isEqualDate } from '../../../scripts/date';
import calendarTemplatesStore from '../../../store/modules/calendar-templates-store';
import ScheduleDateSlots from '../ScheduleDateSlots.vue';
export default {
    name: 'DateItem',
	components: { 
		ScheduleDateSlots 
	},
    props: {
        date: {
            type: Date,
            required: true,
        },
        eventSlots: {
            type: Array,
            required: true,
        },
        maxEventSlots: {
            type: Number,
            default: 5,
        },
        isCurrentMonth: {
            type: Boolean,
            required: true,
        },
        isShowEventSlots: {
            type: Boolean,
            default: true,
        },
		titleIsShort:{
			type: Boolean,
			default: false,
		},
		isSelected: {
			type: Boolean,
			default: false,
		},
		selector: {
			type: Function,

		},
		collapseStep: {
			type: Number,
			default: 1,
		}
    },
    data() {
        return {};
    },
    methods: {
        isToday() {
            return isEqualDate(this.date, new Date());
        },
		isHoliday(date){
			const calendar = this.$store.getters.currentCalendar()
			if (!calendar || !calendar.workDays)
				return false
            return calendar.workDays.isHoliday(date)
        },
        isSpecialDay(date){
			const calendar = this.$store.getters.currentCalendar()
			if (!calendar || !calendar.workDays)
				return false
			return calendar.workDays.isSpecialDate(date)
        },
        getTimeString: getTimeString,
    },
};
</script>

<style scoped>
.schedule-date {
	flex: 1;
	display: flex;
	flex-direction: column;
	justify-content: flex-start;
	align-items: center;
	height: 100%;
	width: 100%;
	border: 1px solid transparent;
	border-radius: 4px;
	background: white;
	transition: border-color .3s;
	transition: box-shadow .3s;
	transition: background .3s;

	position: relative;
}

.is-title-only{
	justify-content: center;
}

.disabled{
	opacity: 0.6;
}

.is-today{
	background: #0075ff;
	color: white;
	height: fit-content;
	padding: 2px;
	border-radius: 4px;
}

.schedule-date__selector{
	position: absolute;
	top: 0;
	left: 0;
	right: 0;
	bottom: 0;
	z-index: 1;
}

.schedule-date__title{
	width: 22px;
	height: 18px;
	display: flex;
	justify-content: center;
	align-items: center;
	font-size: 12px;
	font-weight: bold;
}

.schedule-date__title.short{
	height: 32px;
}


.is-holiday{
	color: #da7f78;
}

.is-holiday.is-today{
	color: white;
}

.empty-message{
	font-size: 10px;
	color: grey;
}

</style>