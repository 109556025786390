<template>
	<div class="calendar-v2" 
	:id="`calendar-v2-${selectedDate.toLocaleDateString()}`"
	ref="calendar">
		<div class="support-message" v-if="supportMessage">
			{{ supportMessage }}
		</div>

		<CalendarDateTitle v-if="isShowDateTitles"
			:selectedDate="selectedDate"
			:columnCount="columnCount"
		/>


		<div class="calendar-v2-table" ref="table">

			<!-- Past time backdrop -->
			<PastTimeBackdrop
				:tableHeader="tableHeader"
				:workTime="workTime"
				:container="$el"
				:columnCount="columnCount"
			/>

			<Hours
				:workTime="workTime"
				:cellHeight="cellHeight"
			/>

			<Column
				v-for="column in tableHeader.leftSide()"
				:key="`column-date-${column.date.toLocaleDateString()}`"
				:column="column"
				:cellHeight="cellHeight"
				:slotCollection="getColumnCollection(column.date)"
				:slotContainer="slotContainer"
				:workTime="workTime.projection(column.date)"
				:columnCount="columnCount"
				:isEditable="isEditable"
			/>

			<Column 
				:column="tableHeader.selectedItem()"
				:cellHeight="cellHeight"
				:slotCollection="getColumnCollection(selectedDate)"
				:slotContainer="slotContainer"
				:workTime="workTime.projection(selectedDate)"
				:columnCount="columnCount"
				:isEditable="isEditable"
				:dateIsSelected="true"
			/>

			<Column
				v-for="column in tableHeader.rightSide()"
				:key="`column-date-${column.date.toLocaleDateString()}`"
				:column="column"
				:cellHeight="cellHeight"
				:slotCollection="getColumnCollection(column.date)"
				:slotContainer="slotContainer"
				:workTime="workTime.projection(column.date)"
				:columnCount="columnCount"
				:isEditable="isEditable"
			/>
		</div>
		
	</div>
</template>

<script>
import { dateIsPast, isEqualDate, pair } from '../../scripts/date';

import CalendarWorkTime from '../../scripts/calendarWorkTime';
import Column from './Column.vue';
import CalendarHeader from '../../scripts/calendarHeader';
import SlotCollection from '../../scripts/slotCollection';
import { getColumnWidth } from '../../scripts/column';

import Hours from './Hours.vue';
import CalendarDateTitle from './CalendarDateTitle.vue';
import PastTimeBackdrop from './PastTimeBackdrop.vue';

export default {
	name: 'CalendarV2',

	components: {
		Column,
		Hours,
		CalendarDateTitle,
		PastTimeBackdrop
	},

	props: {

		calendarUid: {
			type: String,
			required: true,
		},

		selectedDate: {
			type: Date,
			required: true,
		},
		workTime: {
			type: CalendarWorkTime,
			required: true,
		},
		slotCollection : {
			type: SlotCollection,
			required: true,
		},

		columnCount: {
			type: Number,
			default: 1,
			validation: (count) => {
				return count >= 1 && count <= 7
			},
		},

		cellHeight: {
			type: Number,
		},

		isSyncScroll: {
			type: Boolean,
			default: false,
		},
		isShowDateTitles: {
			type: Boolean,
			default: true,
		},
		isEditable: {
			type: Boolean,
			default: true,
		},
	},

	computed: {
		defaultColumnWidth() {
			return getColumnWidth(this.columnCount, false, 45) + 'px';
		},
		selectedColumnWidth() {
			return getColumnWidth(this.columnCount, true, 45) + 'px';
		},
		supportMessage(){
			const rangeStart = new Date(this.selectedDate)
			const rangeEnd = new Date(this.selectedDate)
			rangeEnd.setDate(rangeEnd.getDate() + this.columnCount)

			if (dateIsPast(rangeStart) && dateIsPast(rangeEnd)) {
				return 'Archive mode';
			}
			return null
		},
		columnViewType(){
			if (this.columnCount == 1)
				return "full"
			else if (this.columnCount == 3 || this.columnCount == 5)
				return "middle"
			else if (this.columnCount == 7)
				return "short"
			return "full"
		},
		
	},

	watch: {
		selectedDate() {
			this.tableHeader = new CalendarHeader(this.selectedDate, this.columnCount);
			this.setupColumnsCollections()
		},
		columnCount() {
			this.tableHeader = new CalendarHeader(this.selectedDate, this.columnCount);
			this.setupColumnsCollections()
		}
	},

	created(){
		this.setupColumnsCollections()
	},

	mounted() {
		if (this.isSyncScroll)
			this.syncScroll()

		this.slotContainer = this.$refs['calendar']
	},

	data() {
		return {
			tableHeader: new CalendarHeader(this.selectedDate, this.columnCount),
			slotContainer: null,
			scrollTimer: null,
			columnsCollections: [],
		};
	},

	methods: {
		pair: pair,
		setupColumnsCollections(){
			if (!this.slotCollection)
				return 
			const week = this.tableHeader.week
			if (!week)
				return
			const oldColumnsCollections = this.columnsCollections
			this.columnsCollections = week.map(weekItem => this.slotCollection.collectionByDate(weekItem.date))
			if (oldColumnsCollections.length != 0) {
				oldColumnsCollections.forEach(columnCollection => {
					this.slotCollection.removeCollection(columnCollection)
				})
			}
		},
		getColumnCollection(date){
			if (!date)
				return undefined
			return this.columnsCollections.find(collection => {
				if (!collection || !collection.from)
					return false
				return isEqualDate(collection.from, date)
			})
		},
		syncScroll(){

			let currentCalendar = this.$el
			if (!currentCalendar)
				return
			let currentCalendarTable = this.$el.querySelector('.calendar-v2-table')
			if (!currentCalendarTable)
				return

			let anotherCalendar = Array.from(document.querySelectorAll(".calendar-v2-table"))
				.find(calendar => calendar != this.$el)
			if (!anotherCalendar)
				return

			currentCalendarTable.scrollTo({top: anotherCalendar.scrollTop, left: 0}) 
		},

	},
};
</script>

<style scoped>

.calendar-v2 {
	position: relative;
	width:  100%;
	height: 100%;
	overflow-y: auto;
}

.calendar-v2-table {
	padding-top: 10px;
	display: flex;
	flex-direction: row;
	overflow: hidden;
	padding-right: 16px;
	position: relative;
	margin-bottom: 50px;
}

.calendar-v2.no-scroll {
  	overflow: hidden;
}

.support-message{
	position: fixed;
	top: 25px;
	width: 100%;
	z-index: 4;
	height: 30px;
	display: flex;
	justify-content: center;
	align-items: center;
	font-weight: 500;
}

</style>