<template>
	<div class="schedule-slot">

		<!-- Slot Header -->
		
		<div class="slot-header">
			<div class="slot-start">
				{{ slotStartTime() }}
			</div>
			<div class="slot-color" v-bind:style="{
				background,
			}"></div>
			<div class="slot-title">
				{{ title }}
			</div>
		</div>

		<!-- Slot Content -->

		<div class="slot-content">
			<div class="slot-duration">
				{{ slotDurationTime() }}
			</div>
		</div>
	</div>
</template>

<script>
import { getTimeString } from '../../scripts/date';
import Slot from '../../scripts/slot';
export default {
	name: 'ScheduleSlot',

	props: {
		eventSlot: {
			type: Slot,
			required: true,
		},
	},

	computed: {
		background(){
			return this.eventSlot.settings.cellBackgroundColor ?? 'lavender'
		},
		title(){
			return this.eventSlot.settings.title ?? `Slot ${this.eventSlot.index}`
		},
	},

	methods: {
		slotDurationTime(){
			return `${getTimeString(this.eventSlot.period.start.date)} - ${getTimeString(this.eventSlot.period.end.date)}`
		},
		slotStartTime(){
			return getTimeString(this.eventSlot.period.start.date)
		},
	},
};
</script>

<style scoped>
.schedule-slot {
	position: relative;
	display: flex;
	flex-direction: column;
}
.slot-header{
	display: flex;
	flex-direction: row;
	gap: 8px;
	height: fit-content;
	display: flex;
	align-items: center;
	height: 24px;
}


.slot-start{
	font-size: 12px;
	font-weight: bold;
	padding-right: 6px;
	width: 38px;
}
.slot-color{
	height: 18px;
	width: 4px;
	border-radius: 5px;
}

.slot-duration{
	font-size: 11px;
	font-weight: 500;
	color: #9f9f9f;
	padding-left: 58px;
}

.slot-title{
	font-weight: 500;
}

</style>