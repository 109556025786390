<template>
	<div id="schedule-calendar">

		<!-- Schedule modal -->
		<ScheduleModalTable
			v-if="collapseStep == 2"
			v-model="date"
			:daysCount="daysCount"
			:workTime="workTime"
			:slotCollection="slotCollection"
			:isModalOpen="isModalOpen"
			:closeHandler="closeTableModal"
		/>


		<!-- Current month name -->

		<MonthBar v-model="daysCount"
			:months="months"
			:currentMonthIndex="currentMonth"
			@on-select-date="selectToday"/>

		<!-- Week header -->

		<WeekBar :collapseStep="collapseStep"/>

		<!-- Calendar -->

		<CalendarCollapseBehavior ref="collapse-calendar"
			v-model="collapseStep" 
			:activity="activity"
			:collapseMods="collapseMods"
			:setActivityFunc="setActivityHandler"
			@on-collapse-move="collapseMoveHandler"
			@on-collapse-end="collapseEndHandler">
				<!-- <SwipeableWeek ref="week-calendar" v-model="date" v-if="week"
 					v-show="collapseStep == 0" :week="week"
					:slotCollection="slotCollection"
					:selector="selectDateHandler"
					:checkCurrentMonth="isCurrentMonth"
					:collapseStep="collapseStep"
					:daysCount="daysCount"
					@on-swipe-right="swipeRight"
					@on-swipe-left="swipeLeft"
				/> -->
				<BCarouselListMod
					ref="week-calendar"
					v-if="collapseStep == 0"
					v-model="currentWeek"
					:arrow="false"
					:activity="activity"
					carouselActivity="swipe-calendar"
					:has-drag="calendarIsScrollable"
					:data="weeks" :items-to-show="1"
					@on-set-activity="setActivityHandler"
					@input="swipeHandler">
					<template #item="week"> 
						<Week v-model="date"
							:week="week.list"
							:daysCount="daysCount"
							:slotCollection="slotCollection"
							:collapseStep="collapseStep"
						/>
					</template>
				</BCarouselListMod>
				<BCarouselListMod
					ref="month-calendar"
					v-if="collapseStep == 1 || collapseStep == 2"
					v-model="currentMonth"
					:arrow="false"
					:activity="activity"
					carouselActivity="swipe-calendar"
					:has-drag="calendarIsScrollable"
					:data="months" :items-to-show="1"
					@on-set-activity="setActivityHandler"
					@input="swipeHandler">
					<template #item="month"> 
						<Month v-model="date"
							:month="month.list"
							:daysCount="daysCount"
							:slotCollection="slotCollection"
							:collapseStep="collapseStep"
						/>
					</template>
				</BCarouselListMod>
		</CalendarCollapseBehavior>
	</div>
</template>

<script>
import { cssVh, cssVw } from '../../../scripts/cssUnits';
import BCarouselListMod from '../../BCarouselListMod.vue'
import CalendarWorkTime from '../../../scripts/calendarWorkTime';
import SlotCollection from '../../../scripts/slotCollection';
import ScheduleModalTable from '../ScheduleModalTable.vue';
import Month from './Month.vue'; 
import MonthBar from './MonthBar.vue';
import Week from './Week.vue';
import WeekBar from './WeekBar.vue';
import CalendarCollapseBehavior from './CalendarCollapseBehavior.vue';
import YYYYMMDD, { weekStart, isEqualDate, weekEnd, nextDate, smartDateSelect } from '../../../scripts/date';

const collapseMods = {
	0: {height: 62},
	1: {height: 300},
}

export default {
	name: 'Calendar',
	components: {
		Month,
		Week,
		ScheduleModalTable,
		BCarouselListMod,
		CalendarCollapseBehavior,
		MonthBar,
		WeekBar,
	},
	emits: [
		'on-swipe', 
		'on-select-date', 
		'on-set-activity',
	],
	model: {
		prop: 'days',
		event: 'update:days'
	},
	props: {
		months: {
			type: Array,
			default: () => [],
		},
		selectedDate: {
			type: Date,
			required: true,
		},
		slotCollection: {
			type: SlotCollection,
			required: true,
		},
		workTime: {
			type: CalendarWorkTime,
			required: true,
		},
		activity: {
			type: String,
		},
		days: {
			type: Number,
			required: true
		}
	},

	data() {
		return {
			currentMonth: 0,
			currentWeek: 0,

			initialWidth: 0,

			week: null,

			collapseStep: null,
			isModalOpen: false,
			calendarIsVisible: true,
			calendarIsScrollable: true,
		};
	},

	created() {
		if (this.$route.query.st != undefined)
			this.collapseStep = Number(this.$route.query.st)
		else
			this.collapseStep = 1

		if (this.collapseStep == 0) {
			this.makeWeekCalendar()
		}
	},

	beforeMount(){
		this.setCarouselPosition()
	},

	mounted() {
		// TODO : change the height of the component depends on the current step
		this.withoutTransition(() => {})
	},

	computed: {
		date: {
			get(){
				return this.selectedDate
			},
			set(newDate){
				if (this.collapseStep == 2)
					this.openTableModal()
				this.$emit('on-select-date', newDate)
			}
		},
		ceilSize(){
			return this.$store.getters.tableCeilSize
		},

		weeks(){
			let weeks = []
			this.months.forEach(month => {
				if (!month)
					return 
				let counter = 0
				while (month[counter]) {
					let exists = weeks.find(week => isEqualDate(week[0], month[counter][0]))
					if (!exists)
						weeks.push(month[counter])
					counter += 1
				}
			})
			return weeks
		},
		collapseMods(){
			const mods = collapseMods
			mods[2] = {height : cssVh*100 - 82 - 27 - 30 - 21}

			return mods
		},
		daysCount : {
			get(){
				return this.days
			},
			set(value){
				this.$emit('update:days', value)
			}
		}
	},

	watch: {
		collapseStep(newValue, oldValue) {

			if (oldValue == null)
				return

			let query = {
				st: newValue,
				dt: YYYYMMDD(this.selectedDate),
			}

			this.updateUrl(query)


			this.$nextTick(() => {
				this.withoutTransition(() => {
					if (newValue == 0){
						this.makeWeekCalendar()
					} else if (newValue == 1) {
						this.makeMonthShortCalendar()
					} else if (newValue == 2) {
						this.makeMonthFullCalendar()
					}
				})
			})
		},
	},

	methods: {
		setCarouselPosition(){
			this.currentMonth = 1
			this.currentWeek = this.getWeekIndexByDate(this.date)
			console.log('this.date :>> ', this.date);
			console.log('this.currentWeek :>> ', this.currentWeek);
		},
		setActivityHandler(value){
			this.$emit('on-set-activity', value)
		},
		openTableModal(){
			let staticMenu = document.getElementById('static__menu')
			staticMenu.style["zIndex"] = 0

			let header = document.getElementById('header')
			header.style["zIndex"] = 0

			this.isModalOpen = true
		},
		closeTableModal(){
			let staticMenu = document.getElementById('static__menu')
			staticMenu.style["zIndex"] = null

			let header = document.getElementById('header')
			header.style["zIndex"] = null

			this.isModalOpen = false
		},
		selectToday(){
			this.date = smartDateSelect(new Date(), this.daysCount)
			this.$nextTick().then(() => {
				const newDateWeekIndex = this.getWeekIndexByDate(this.date)
				const newDateMonthIndex = this.getMonthIndexByWeek(this.weeks[newDateWeekIndex])
				if (newDateWeekIndex != this.currentWeek) {
					this.currentWeek = newDateWeekIndex
				}
				if (newDateMonthIndex != this.monthIndex) {
					this.currentMonth = newDateMonthIndex
				}
			})
		},

		swipeHandler(newValue){
			if (this.collapseStep == 0)
				this.swipeWeekHandler(newValue)
			else if (this.collapseStep == 1 || this.collapseStep == 2)
				this.swipeMonthHandler(newValue)
		},

		swipeWeekHandler(newActiveItemIndex){
			const calendar = this.$refs['week-calendar']
			let weekIndex = newActiveItemIndex
			const monthIndex = this.getMonthIndexByWeek(this.weeks[weekIndex])

			this.$emit('on-swipe', monthIndex)
			if (monthIndex == 0) {
				
				this.withoutTransition(() => {
					this.currentWeek += 4
					weekIndex += 4
					calendar.scrollIndex = weekIndex
				})
			}

			if (monthIndex != 0 && this.currentMonth != monthIndex){
				this.currentMonth = monthIndex
			}	
			if (weekIndex != 0 && weekIndex != calendar.scrollIndex)
				calendar.scrollIndex = weekIndex
			const currentWeek = this.weeks[weekIndex]
			const currentWeekStart = currentWeek[0]
			const newSelectedDate = new Date(this.date)

			if (currentWeekStart > this.date)
				newSelectedDate.setDate(this.date.getDate() + 7)
			else
				newSelectedDate.setDate(this.date.getDate() - 7)
			
			this.date = newSelectedDate
		},

		swipeMonthHandler(newActiveItemIndex){
			const calendar = this.$refs['month-calendar']
			const monthIndex = newActiveItemIndex

			this.$emit('on-swipe', monthIndex)

			if (monthIndex != 0 && monthIndex != calendar.scrollIndex)
				calendar.scrollIndex = monthIndex
			
			if (monthIndex == 0) {
				this.withoutTransition(() => {
					this.currentMonth = 1
					calendar.scrollIndex = 1
				})
			}
			const currentMonth = this.months[monthIndex]
			const currentMonthStart = new Date(currentMonth[0][6])
			currentMonthStart.setFullYear(currentMonthStart.getFullYear(), currentMonthStart.getMonth(), 1)
			this.date = smartDateSelect(currentMonthStart, this.daysCount)
		},	

		monthIncludes(month, date){
			let monthDate = new Date(month[1][0])
			return monthDate.getMonth() == date.getMonth() && 
					monthDate.getFullYear() == date.getFullYear()
		},

		withoutTransition(action) {
			const calendar = this.collapseStep == 0 ?
				this.$refs['week-calendar'] :
				this.$refs['month-calendar']
			

			if (!calendar)
				return action()

			
			let carouselSlides = calendar.$el.querySelector('.carousel-slides');
			carouselSlides.style.transition = 'none';
			action()
			const currentValue = this.collapseStep == 0?
				this.currentWeek : this.currentMonth
			carouselSlides.style.transform = `translateX(-${100*cssVw*currentValue}px)`
			setTimeout(() => carouselSlides.style.transition = null, 0);
		},
		isCurrentMonth(date){
			let month = this.months[this.currentMonth]
			let result = this.monthIncludes(month, date)
			return result
		},
		getMonthIndexByWeek(week){
			let monthCounter = 0
			let monthContainWeek
			while (this.months[monthCounter]) {
				monthContainWeek = Object.values(this.months[monthCounter])
					.find((monthWeek) => {
						return isEqualDate(monthWeek[0], week[0]) &&
								isEqualDate(monthWeek[6], week[6])
					})
				if (monthContainWeek)
					return (monthCounter);
				monthCounter++;
			}
			return null
		},
		getWeekIndexByDate(date){
			date.setHours(0, 0, 0, 0)
			return this.weeks.findIndex(week => {
				week[0].setHours(0, 0, 0, 0)
				week[6].setHours(0, 0, 0, 0)
				return week[0] <= date && week[6] >= date
			})
		},
		
		collapseMoveHandler(deltaY){

			let fullShortDifference = ( this.collapseMods[2].height - collapseMods[1].height) * 0.3
			let slotTitles = Array.from(document.querySelectorAll('.schedule-date__item-title'))


			slotTitles.forEach(title => {
				// Increase titles opacity
				if (this.collapseStep == 1 && deltaY > 0) {
					title.style.display = 'block'
					title.style.opacity = 1 - (1 - (deltaY / fullShortDifference) + 0.6) 
				} 
				// Decrease titles opacity
				if (this.collapseStep == 2 && deltaY < 0) { 
					title.style.opacity = 1 + (deltaY / fullShortDifference) + 0.35
				}
			})	
		},

		collapseEndHandler(){
			let slotTitles = Array.from(document.querySelectorAll('.schedule-date__item-title'))
			slotTitles.forEach(title => {
				if (this.collapseStep == 2) {
					title.style.display = 'block'
					title.style.opacity = 1
				} else {
					title.style.display = 'none'
				}
			})
		},

		makeWeekCalendar(){
			let weekDate 

			if (this.isCurrentMonth(this.selectedDate)){
				weekDate = weekStart(this.selectedDate)
			} else {
				weekDate = weekStart(this.months[this.currentMonth][0][6])
			}
			this.currentWeek = this.getWeekIndexByDate(weekDate)
		},
		makeMonthShortCalendar(){
		},
		makeMonthFullCalendar(){
		},
		// swipeRight(){
		// 	const newDate = new Date(this.week[0]);
		// 	newDate.setDate(newDate.getDate() - 1)
		// 	if (this.daysCount != 1)
		// 		this.date = newDate
		// 	if (this.daysCount == 1 && this.week[this.week.length - 1] < this.date)
		// 		this.date = this.week[this.week.length - 1]
		// },
		// swipeLeft(){
		// 	const newDate = new Date(this.week[0]);
		// 	newDate.setDate(newDate.getDate() + 1)
		// 	if (this.daysCount != 1 || newDate > this.date)
		// 		this.date = newDate
		// },

		updateUrl(query){
			if (this.$route.query.dt != undefined && query.dt == undefined)
				query.dt = this.$route.query.tb

			if (this.$route.query.tb != undefined && query.tb == undefined)
				query.tb = this.$route.query.tb

			if (this.$route.query.st != undefined && query.st == undefined)
				query.st = this.$route.query.st

			if (this.$route.query.dr != undefined && query.dr == undefined)
				query.dr = this.$route.query.dr

			this.$router.replace({
				name : 'calendar-schedule',
				params: {
					calendarUid: this.$route.params.calendarUid
				},
				query: query,
			})
		},

	},
};
</script>

<style>
.carousel-list.has-shadow{
	box-shadow: none;
}
.carousel-list{
	height: inherit;
}
</style>

<style scoped>

#schedule-calendar{
	height: fit-content;
	position: relative;
	width: 100%;
	padding-top: 10px;
	background: white;
	position: relative;
    z-index: 2;
}


.open-calendar-animation{
	animation: open-calendar 0.3s ease-in-out;
}

.month-padding{
	padding: 0 15px 10px 0;
	height:	inherit;
}

.close-calendar-animation{
	animation: close-calendar 0.3s ease-in-out;
}

@keyframes open-calendar {
	0% {
		max-height: 54px;
	}
	100% {
		max-height: 336px;
	}
}

@keyframes close-calendar {
	0% {
		max-height: 336px;
	}
	100% {
		max-height: 54px;
	}
}



.user-schedule__header-toggle{
	position: absolute;
	right: 10px;
	top: 10px;
	height: 30px;
	width: 30px;
	display: flex;
	justify-content: center;
	align-items: center;
	z-index: 2;
}

.button-container{
	height: 24px;
	transition: transform 0.3s;
}
.calendar-header__varnish{
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 54px;
}
.button-container.opened{
	transform: rotate(180deg);
}



#collapse-cover{
	touch-action: none;
	width: 100%;
	min-height: 62px;
	max-height: calc(100dvh - 32px - 10px - 27px - 30px - 50px  );
	overflow: hidden;
	border-bottom: 1.5px solid rgb(232, 232, 232);
}
</style>