/**
 * Asserts a value is beetween min and max
 * @param val
 * @param min
 * @param max
 * @returns {number}
 */
function bound(val, min, max) {
    return Math.max(min, Math.min(max, val))
}

/**
 * Native modulo bug with negative numbers
 * @param n
 * @param mod
 * @returns {number}
 */
function mod(n, mod) {
    return ((n % mod) + mod) % mod
}

/**
 * +/- function to native math sign
 */
function signPoly(value) {
    if (value < 0) return -1
    return value > 0 ? 1 : 0
}
export const sign = Math.sign || signPoly

export {bound, mod}